import React from 'react'
import { Heading, Button, Text, theme } from '@plurall/elo-beta'
import { EducationalSolutionsCard } from 'components'
import { useMediaQuery } from 'react-responsive'
import { CARDS } from 'constants/mocks'

import styles from './Family.module.scss'
import { imgMascoteAcenando } from '../../assets/images'

const Family = () => {
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const handleLinkSolutions = () => {
    window.location.href = '#solutions'
  }

  return (
    <div className={styles.container}>
      <div className={styles['banner-container']}>
        <section className={styles['section-left']}>
          <h1 data-test-id='banner-title' className={styles.title}>Plurall Store Family a loja educacional das famílias</h1>

          <div data-test-id='banner-subtitle' className={styles['container-subtitle']}>
            <Heading element='h5' textAlign={isMobile ? 'center' : 'left'}>
              <span className={styles.subtitle}>
                Aprenda de forma mais rápida, divertida e personalizada! A Plurall Store Family é do
                seu jeito, é a sua cara!
              </span>
            </Heading>
          </div>

          <div className={styles['section-explore-solutions']}>
            <Button
              dataTestId='button-explore-solutions'
              onClick={() => handleLinkSolutions()}
              highlight
              fullWidth
              size='large'
            >
              <Text size='t2' bold>
                Explorar soluções
              </Text>
            </Button>
          </div>
        </section>
        <section className={styles['section-right']}>
          <div className={styles['container-img']}>
            <img
              src={imgMascoteAcenando}
              alt='Imagem do mascote acenando'
              className={styles['img-mascot-desktop']}
            />
          </div>
        </section>
      </div>
      <div className={styles['cards-container']} id='solutions'>
        <Heading dataTestId='solutions-title' element='h5' secondary>
          Soluções educacionais
        </Heading>

        <div data-test-id='test' className={styles.cards}>
          {CARDS.map(card => (
            <EducationalSolutionsCard
              key={card.id}
              logoImg={card.logoImg}
              title={card.title}
              subtitle={card.subtitle}
              footerItems={card.footerItems}
              link={card.link}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Family

const trackingEventNames = {
  TryItButton: {
    sectionBannerClick: 'section_banner:click',
    sectionForTeachersClick: 'section_for_teachers:click',
    sectionTryItForFreeClick: 'section_try_it_for_free:click',
    sectionDoubtsClick: 'section_doubts:click',
  },
  AccessOrToSignButton: {
    freePlanClick: 'free:planos:click',
    quarterlyPlanClick: 'trimestral:planos:click',
    annualPlanClick: 'anual:planos:click',
  },
  QuarterlyOrAnnualRadioButton: {
    quarterlyPlanSelect: 'trimestral:planos:selected',
    annualPlanSelect: 'anual:planos:selected',
  },
  HeaderLandingPage: {
    checkoutButton: 'adapta:landing_page:header_sign_button:click',
    senecaButton: 'adapta:landing_page:header_access_button:click',
  },
  Banner: {
    regiterStudent: 'adapta:landing_page:sign_banner:click',
  },
  goToPaymentClick: 'ir_para_pagamento:click',
  goToSeneca: 'indo_para_o_seneca:redirect',
  accessAdaptaClick: 'acessar_adapta:click',
  manageSubscriptionsClick: 'gerenciar_assinaturas:click',
  helpCenterClick: 'central_de_ajuda:click',
  addReponsibleDataClick: 'adicionar_responsavel:click',
  cancelRegisterResponsibleClick: 'cancelar_cadastro_responsavel:click',
  signatureButton: 'adapta:landing_page:footer_sign_now_button:click',
}
export { trackingEventNames }

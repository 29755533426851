import { loader } from 'graphql.macro'
import { Client } from 'utils'
import ClientStore from 'utils/clientStore'

const createOrder = loader('./graphql/createOrder.graphql')
const getCheckout = loader('./graphql/getCheckout.graphql')
const getSubscriptionPlans = loader('./graphql/getSubscriptionPlan.graphql')
const getCurrentSubscription = loader('./graphql/getCurrentSubscription.graphql')
const getSubscriptionStatus = loader('./graphql/getSubscriptionStatus.graphql')
const profile = loader('./graphql/getProfile.graphql')
const getMySubscriptions = loader('./graphql/getMySubscriptions.graphql')
const getMyPayments = loader('./graphql/getMyPayments.graphql')
const cancelSubscription = loader('./graphql/cancelSubscription.graphql')
const getActiveService = loader('./graphql/getActiveService.graphql')

const client = Client()
const clientStore = ClientStore()

const handleToast = (message, variant) =>
  window?.PLURALL_ELO_TOAST_CONTAINER.addToast({
    text: message,
    variant,
    autoClose: false,
    seconds: 6,
    disableButtonClose: true,
    onDismiss: () => {},
  })

// Rest
export const getTerm = async () => {
  // TODO: The params, it is fix because that we need to added it on database manual
  try {
    const response = await clientStore.restClient.get('/acceptance-term/term/term-01')
    return response
  } catch (error) {
    handleToast(
      'Houve uma falha ao processar a sua reserva. Por favor, tente novamente mais tarde ou entre em contato com o nosso suporte.',
      'negative',
    )
    return null
  }
}

export const acceptanceTerm = async data => {
  const response = await clientStore.restClient.post('/acceptance-term/acceptance', data)
  return response
}

export const postResponsible = async userData => {
  const response = await clientStore.restClient.post('/responsible/person/partial', userData)

  return response
}

export const getResponsible = async (userId, isPartial) => {
  const response = await clientStore.restClient.get(
    `/responsible/user/filters?userid=${userId}&isPartial=${isPartial}`,
  )

  return response.data
}

// Graphql

// Mutations
export const mutationCreateOrder = async subscriptionPlanId => {
  const variables = { subscriptionPlanId }

  const response = await clientStore.graphqlClient.mutate({
    mutation: createOrder,
    variables,
  })

  return response
}

export const mutationOrder = (subscriptionPlanId, schoolSelected, responsible) => {
  const variables = {
    subscriptionPlanId,
    schoolId: schoolSelected,
    paymentResponsible: {
      id: responsible.id,
      cpf: responsible.cpf,
    },
  }

  return clientStore.graphqlClient.mutate({ mutation: createOrder, variables }).then(
    async ({ data }) => {
      const { response, errors } = await queryCheckout(data, responsible)
      if (errors) {
        return { errors }
      }
      return response
    },
    ({ graphQLErrors }) => ({
      errors: graphQLErrors,
    }),
  )
}

// Queries
export const querySubscriptionPlans = async serviceId => {
  const variables = { serviceId }

  return clientStore.graphqlClient
    .query({ query: getSubscriptionPlans, variables })
    .then(({ data }) => {
      const { subscriptionPlan } = data

      return { loading: false, plans: subscriptionPlan }
    })
    .catch(err => {
      const { detail: message, status, title } = err.graphQLErrors[0]

      return { loading: false, error: { message, status, title } }
    })
}

export const queryCheckout = async (data, responsible) => {
  const { subscriptionId } = data?.createOrder?.result

  const variables = {
    description: 'Estudo adaptativo com neurociência',
    redirectUrl: `${process.env.REACT_APP_HOST}/adapta/status-pedido/${subscriptionId}`,
    responsibleData: {
      name: responsible.name,
      email: responsible.email,
    },
    subscriptionId,
  }

  return clientStore.graphqlClient
    .query({
      query: getCheckout,
      variables,
    })
    .then(response => ({ response }))
    .catch(({ graphQLErrors }) => ({
      errors: graphQLErrors,
    }))
}

export const queryCurrentService = async () => {
  const response = await clientStore.graphqlClient.query({
    query: getCurrentSubscription,
    variables: { serviceId: 2 },
  })

  return response
}

export const queryOrderStatus = async variables => {
  const {
    data: { getSubscription },
    errors,
  } = await clientStore.graphqlClient.query({
    query: getSubscriptionStatus,
    variables,
  })

  return {
    ...getSubscription,
    ...errors,
  }
}

export const queryMySubscriptions = async () => {
  const { data } = await clientStore.graphqlClient.query({
    query: getMySubscriptions,
    variables: null,
  })

  return data.me.listSubscription
}

export const mutationCancelSubscriptions = async subscriptionId => {
  const variables = { subscriptionId }

  try {
    const response = await clientStore.graphqlClient.mutate({
      mutation: cancelSubscription,
      variables,
    })

    return { success: true, message: response }
  } catch (error) {
    return { success: false, message: error.graphQLErrors[0].message }
  }
}

export const getProfile = async positionStatusId => {
  const response = await client.graphqlClient.query({
    query: profile,
    variables: { positionStatusId },
  })

  return response
}

export const queryMyPayments = async () => {
  const { data } = await clientStore.graphqlClient.query({
    query: getMyPayments,
    variables: null,
  })

  return data
}

export const getUserService = async () => {
  const response = await client.graphqlClient.query({
    query: getActiveService,
    variables: null,
  })

  return response
}

import React, { useState } from 'react'
import { Heading } from '@plurall/elo-beta'

import { ContentForSchoolStaff, MaterialDialog } from 'components'
import { MATERIALS_URL } from 'constants/mocks'
import styles from './ForSchoolStaff.module.scss'

import { imgBrowserAdapta } from '../../assets/images'
import { contentForSchoolStaff } from './contentForSchoolStaff'

const ForSchoolStaff = () => {
  const [showMaterialDialog, setShowMaterialDialog] = useState(false)
  const [contentSelected, setContentSelected] = useState()

  const handleContentSelected = content => {
    setContentSelected(content)
    setShowMaterialDialog(true)
  }

  return (
    <div className={styles.container}>
      {showMaterialDialog && (
        <MaterialDialog
          isImg={contentSelected.url !== MATERIALS_URL.createCustomClasses}
          title={contentSelected.title}
          url={contentSelected.url}
          onClose={() => setShowMaterialDialog(false)}
        />
      )}
      <div className={styles.header}>
        <div className={styles['header-subtitle']}>
          <Heading element='h6' noParagraph>
            Como o
          </Heading>
          &nbsp;
          <Heading element='h6' variant='accent' secondary noParagraph>
            Plurall Adapta
          </Heading>
          &nbsp;
          <Heading element='h6' noParagraph>
            funciona
          </Heading>
        </div>
        <div className={styles['header-title']}>
          <Heading element='h1' noParagraph>
            Experimente e comece agora mesmo a impulsionar o aprendizado dos seus alunos.
          </Heading>
        </div>
      </div>
      <div className={styles['first-section']}>
        <div className={styles['left-item']}>
          <div>
            {contentForSchoolStaff.map(({ numberCircle, title, url, content }) => (
              <ContentForSchoolStaff
                key={numberCircle}
                numberCircle={numberCircle}
                url={MATERIALS_URL[url]}
                title={title}
                content={content}
                handleContentSelected={handleContentSelected}
              />
            ))}
          </div>
        </div>
        <div className={styles['right-item']}>
          <img
            width={463}
            loading='lazy'
            src={imgBrowserAdapta}
            alt='Imagem da sessão dos professores'
            className={styles['img-section-teachers']}
            data-test-id='image-section-teacher'
          />
        </div>
      </div>
    </div>
  )
}

export default ForSchoolStaff

import React, { useEffect, useState } from 'react'

import { Heading, Text, Grid, Row, Tabs, Spinner } from '@plurall/elo-beta'
import { queryMySubscriptions } from 'services'
import { MySubscriptionsCard, NoSubscriptions, QuickAccess } from 'components'
import { STATUS_SERVICE } from 'constants/mocks'

import styles from './MySubscriptions.module.scss'

const MySubscriptions = () => {
  const [activeSubscriptions, setActiveSubscriptions] = useState([])
  const [expiredSubscriptions, setExpiredSubscriptions] = useState([])
  const [quarterlyPlan, setQuarterlyPlan] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getMySubscriptions = async () => {
      const { result } = await queryMySubscriptions()

      if (result.subscriptions) {
        setLoading(false)

        const active = result.subscriptions.filter(res => res.status === STATUS_SERVICE.active)
        const expired = result.subscriptions.filter(res => res.status === STATUS_SERVICE.inactive)
        const quarterly =
          active.length > 0 && active[0].subscriptionPlan.title === 'Premium trimestral'

        setQuarterlyPlan(quarterly)
        setActiveSubscriptions(active)
        setExpiredSubscriptions(expired)
      }
    }

    getMySubscriptions()
  }, [])

  return (
    <div>
      <div className={styles.header}>
        <Heading element='h1' noParagraph>
          Minhas assinaturas
        </Heading>
        <Text size='t3' shy semibold>
          Gerencie suas assinaturas da Plurall Store
        </Text>
      </div>

      <Tabs baseLine>
        <div label='Ativas' badge={{ text: activeSubscriptions.length }} dataTestId='active-tab'>
          <Row>
            <Grid desktop={7} tablet={12} mobile={12}>
              {loading && (
                <div className={styles['spinner-container']}>
                  <Spinner />
                </div>
              )}

              {!loading && activeSubscriptions.length < 1 ? (
                <NoSubscriptions title='Você não possui assinaturas ativas' />
              ) : (
                <section className={styles['section-card']}>
                  {activeSubscriptions.length > 0 &&
                    activeSubscriptions.map(sub => (
                      <MySubscriptionsCard
                        key={sub.orderId}
                        id={sub.id}
                        numberOrder={sub.orderId}
                        renovationDate
                        valueDate={sub.endAt}
                        planType={sub.subscriptionPlan.title}
                        purchaseData={sub.beginsAt}
                        amount={sub.subscriptionPlan.value}
                        quarterlyPlan={quarterlyPlan}
                        product={sub.subscriptionPlan.product}
                        logo={sub.subscriptionPlan.service.logo}
                        activeSubscription
                        canCancel={sub.canCancel}
                      />
                    ))}
                </section>
              )}
            </Grid>

            <Grid desktop={4} tablet={12} mobile={12}>
              <QuickAccess />
            </Grid>
          </Row>
        </div>
        <div
          label='Expiradas'
          badge={{ text: expiredSubscriptions.length }}
          dataTestId='expired-tab'
        >
          <Row>
            <Grid desktop={7} tablet={12} mobile={12}>
              {expiredSubscriptions.length < 1 ? (
                <NoSubscriptions title='Você não possui assinaturas expiradas' />
              ) : (
                <section className={styles['section-card']}>
                  {expiredSubscriptions.length > 0 &&
                    expiredSubscriptions.map(sub => (
                      <MySubscriptionsCard
                        key={sub.orderId}
                        numberOrder={sub.orderId}
                        renovationDate
                        valueDate={sub.endAt}
                        planType={sub.subscriptionPlan.title}
                        purchaseData={sub.beginsAt}
                        amount={sub.subscriptionPlan.value}
                        quarterlyPlan={quarterlyPlan}
                        product={sub.subscriptionPlan.product}
                        logo={sub.subscriptionPlan.service.logo}
                      />
                    ))}
                </section>
              )}
            </Grid>

            <Grid desktop={4} tablet={12} mobile={12}>
              <QuickAccess />
            </Grid>
          </Row>
        </div>
      </Tabs>
    </div>
  )
}
export default MySubscriptions

import React, { useEffect, useState } from 'react'
import { Row, Grid, Heading, Card, Badge, Text, Banner } from '@plurall/elo-beta'

import { PAYMENT_STATUS } from 'constants/mocks'
import { QuickAccess } from 'components'
import { queryMyPayments } from 'services'
import { getFormattedDate } from 'utils/formattedDate'
import { replaceAndDecimalNumber } from 'utils/common'

import styles from './MyPayments.module.scss'

const MyPayments = () => {
  const [paymentsList, setPaymentsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [years, setYears] = useState([])

  useEffect(() => {
    const getMyPayments = async () => {
      const { me } = await queryMyPayments()

      const { payments } = me.listPayments.result
      const paymentsObj = new Map()

      payments.map(payment => {
        const { order } = payment
        const month = getFormattedDate(parseFloat(order.orderStatus.createdAt), { month: 'long' })
        const year = getFormattedDate(parseFloat(order.orderStatus.createdAt), { year: 'numeric' })
        setYears(y => [...y, year])

        if (!paymentsObj.get(month)) paymentsObj.set(month, [])

        const paymentObj = paymentsObj.get(month)
        paymentObj.push(payment)

        return month
      })

      const paymentsArray = Array.from(paymentsObj, ([name, value]) => ({ name, value }))

      setPaymentsList(paymentsArray)
      setLoading(false)
    }

    getMyPayments()
  }, [])

  const handleStatus = status => {
    if (status === PAYMENT_STATUS.AWAITING_PAYMENT) {
      return { variant: 'informative', status: 'Aguardando pagamento' }
    } else if (status === PAYMENT_STATUS.NO_STATUS) {
      return { variant: 'negative', status: 'Falha' }
    }
    if (status === PAYMENT_STATUS.PAYMENT_FINALIZED) {
      return { variant: 'positive', status: 'Pago' }
    }
    return { variant: null, status: 'Cancelado' }
  }

  return (
    <div>
      <div className={styles.container}>
        <Row>
          <Grid desktop={7} tablet={12} mobile={12}>
            <Heading element='h1' noParagraph>
              Meus pagamentos
            </Heading>
            <div className={styles['main-container']}>
              {!loading &&
                (paymentsList.length > 0 ? (
                  paymentsList.map((payment, index) => (
                    <>
                      <Heading element='h6' noParagraph secondary>
                        <div className={styles.heading}>
                          {payment.name} - {years[index]}
                          <div className={styles.badge}>
                            <Badge>{payment.value.length}</Badge>
                          </div>
                        </div>
                      </Heading>

                      {payment.value.map(({ order, subscription }) => (
                        <Card>
                          <div className={styles['card-container']}>
                            <div className={styles['left-item']}>
                              <Text size='t3' secondary>
                                <span className={styles['payment-date']}>
                                  {getFormattedDate(parseFloat(order.orderStatus.createdAt))}
                                </span>
                              </Text>
                              <Text size='t3' secondary>
                                <span className={styles['product-name']}>
                                  {subscription.subscriptionPlan.product}
                                </span>
                              </Text>
                            </div>

                            <div className={styles['right-item']}>
                              <div className={styles['section-status']}>
                                <Text size='t3' secondary>
                                  R$ {replaceAndDecimalNumber(order.total)}
                                </Text>
                                <Badge variant={handleStatus(order.orderStatus.status).variant}>
                                  {handleStatus(order.orderStatus.status).status}
                                </Badge>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </>
                  ))
                ) : (
                  <div className={styles['section-no-payment']}>
                    <Text size='t1' semibold>
                      Nenhum pagamento realizado
                    </Text>
                    <Text size='t3' dimmed alignment='center'>
                      <span className={styles.subtitle}>
                        Quando você contratar uma solução educacional, as informações sobre os seus
                        pagamentos aparecerão aqui. Para conhecer as soluções disponíveis, acesse o
                        catálogo Plurall Store.
                      </span>
                    </Text>
                    <Text size='t2' element='a' href='#' noUnderline>
                      Acessar catálogo
                    </Text>
                  </div>
                ))}
            </div>
          </Grid>

          <Grid desktop={4} tablet={12} mobile={12}>
            <div className={styles['right-side']}>
              <div className={styles['section-banner']}>
                <Banner title='Nota Fiscal Eletrônica' secondary>
                  <Text size='t3' secondary>
                    A nota será emitida e liberada para download assim que o serviço for considerado
                    prestado.
                  </Text>
                </Banner>
              </div>

              <QuickAccess />
            </div>
          </Grid>
        </Row>
      </div>
    </div>
  )
}

export default MyPayments

const getCookieLoggedName = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'pull_request':
      return 'staging_shared_cookie'
    case 'development':
      return 'development_shared_cookie'
    case 'staging':
      return 'staging_shared_cookie'
    case 'production':
      return 'shared_cookie'
    default:
      return 'staging_shared_cookie'
  }
}

const getToken = () => {
  const r = document.cookie.match(`\\b${getCookieLoggedName()}=([^;]*)\\b`)
  return r ? r[1] : undefined
}

const setToken = token => {
  const sharedCookieName = getCookieLoggedName()
  document.cookie = `${sharedCookieName}=${token};path=/;domain=.plurall.net`
}

export { getToken, setToken }

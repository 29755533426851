import React from 'react'
import PropTypes from 'prop-types'
import { trackingEventNames } from 'constants/tracking'

import { Text, Button } from '@plurall/elo-beta'
import { handleRedirectSeneca } from 'utils/common'

import styles from './HeaderHome.module.scss'

const HeaderHome = ({ registerFreemium, loading }) => {
  const handleAccess = async () => {
    window.PLURALL_TRACKER.track(trackingEventNames.HeaderLandingPage.senecaButton)
    handleRedirectSeneca()
  }

  return (
    <>
      {!loading && registerFreemium && (
        <div className={styles['header-home']}>
          <div className={styles['header-home-text']}>
            <Text size='t4'>Já tenho uma conta no Adapta.</Text>
          </div>

          <div>
            <Button secondary onClick={() => handleAccess()} dataTestId='button-header-home'>
              Acessar
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

HeaderHome.propTypes = {
  registerFreemium: PropTypes.bool,
  loading: PropTypes.bool,
}

export default HeaderHome

import { useState } from 'react'

const useFreemiumAccess = ({ openResponsibleDialog }) => {
  const [loadingRedirect, setLoadingRedirect] = useState(false)

  const freemiumAccess = async ({ user, term }) => {
    setLoadingRedirect(true)
    const openedDialog = await openResponsibleDialog(user, term)
    setLoadingRedirect(false)
    if (!openedDialog) {
      setTimeout(() => {
        window.scrollTo(0, 0)
        window.location.reload()
      }, 500)
    }
  }

  return { freemiumAccess, loadingRedirect }
}

export default useFreemiumAccess

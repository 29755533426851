import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { getResponsible, mutationCreateOrder, queryCurrentService } from 'services'
import { handleRedirectSeneca, hasRole } from 'utils/common'
import { ROLE_STUDENT_ID, SUBSCRIPTION_PLAN_ID } from 'constants/mocks'
import { trackingEventNames } from 'constants/tracking'

const ResponsibleDialogContext = createContext()

const ResponsibleDialogProvider = ({ children }) => {
  const [showResponsibleDialog, setShowResponsibleDialog] = useState(false)

  async function openResponsibleDialog(user, term) {
    const tracker = trackingEventNames.TryItButton.sectionBannerClick
    window.PLURALL_TRACKER.track(tracker)

    const { data } = await queryCurrentService()

    if (data.me.currentSubscription) {
      handleRedirectSeneca(user, term)
      return false
    }

    const responsible = await getResponsible(user?.id, false)
    const partialResponsible = await getResponsible(user?.id, true)
    if (hasRole(ROLE_STUDENT_ID, user) && responsible.length < 1 && partialResponsible.length < 1) {
      setShowResponsibleDialog(true)
      return true
    }

    await mutationCreateOrder(SUBSCRIPTION_PLAN_ID)
    handleRedirectSeneca(user, term)
    return false
  }

  function closeResponsibleDialog() {
    setShowResponsibleDialog(false)
  }

  return (
    <ResponsibleDialogContext.Provider
      value={{
        showResponsibleDialog,
        openResponsibleDialog,
        closeResponsibleDialog,
      }}
    >
      {children}
    </ResponsibleDialogContext.Provider>
  )
}

ResponsibleDialogProvider.propTypes = {
  children: PropTypes.node,
}

export const useResponsibleDialog = () => useContext(ResponsibleDialogContext)

export default ResponsibleDialogProvider

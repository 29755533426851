import React from 'react'
import PropTypes from 'prop-types'
import {
  GridContainer,
  Row,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  theme,
} from '@plurall/elo-beta'
import { useMediaQuery } from 'react-responsive'

import { SectionTryIt } from '../index'

import styles from './Doubts.module.scss'

const Doubts = ({ user, term, student }) => {
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })
  const year = new Date().getFullYear()

  return (
    <GridContainer>
      <Row className={styles.container}>
        <Row className={styles['section-title']}>
          <div className={styles.title}>
            <Heading dataTestId='title' element='h1' textAlign='center' noParagraph>
              Dúvidas de como estudar melhor? A gente te ajuda.
            </Heading>
          </div>
        </Row>

        <Row className={styles['section-accordion']}>
          <div className={styles.accordion}>
            <Accordion small={isMobile}>
              <AccordionItem
                dataTestId='accord-how-to'
                onHeaderClick={() => {}}
                title='Como estudar com o Plurall Adapta?'
                iconDirection='right'
              >
                <div className={styles['accordion-item']}>
                  <Text secondary size={isMobile ? 't3' : 't2'}>
                    Recomendamos o uso da plataforma durante 20 a 30 minutos, por dia, ao longo do
                    ano todo. Esse tempo é o ideal para que você entenda e aprenda o conteúdo. No
                    período que antecede as provas, você pode usar a plataforma com mais frequência,
                    por exemplo, até 2 horas por dia, estudando para todas as matérias.
                  </Text>
                </div>
              </AccordionItem>

              <AccordionItem
                dataTestId='accord-about'
                onHeaderClick={() => {}}
                title='Como sei se essa plataforma é para mim?'
                iconDirection='right'
              >
                <div className={styles['accordion-item']}>
                  <Text secondary size={isMobile ? 't3' : 't2'}>
                    <p>
                      O Plurall Adapta by Seneca propõe uma organização de estudo eficiente em uma
                      aprendizagem individualizada, e que utiliza as próprias informações do aluno
                      para gerar trilhas de estudo. Uma vez que a plataforma faz uso de inteligência
                      artificial e gamifica as atividades, o aluno aprende se divertindo e sem ficar
                      entediado, combinando elementos visuais e de leitura, mesclando flashcard,
                      gifs, imagens e informações textuais. A plataforma, portanto, é indicada para
                      quem deseja estudar de forma autônoma e melhorar o desempenho com o uso de
                      estratégias de aprendizagem comprovadas cientificamente.
                    </p>
                    <p>
                      Os alunos poderão acessar conteúdos em sequência, revisitar pontos de maior
                      atenção, desenvolver uma rotina de estudos personalizada e até receber
                      sugestões do algoritmo Gênio com muito mais segurança no direcionamento dos
                      estudos e melhora do desempenho.
                    </p>
                    <br />
                    <p>
                      Durante o ano de {year}, as disciplinas ofertadas são: <br />• Língua
                      Portuguesa, Ciências, Matemática, História e Geografia (para o Ensino
                      Fundamental II); <br />• Língua Portuguesa, Ciências, Matemática, História,
                      Geografia e Ciências (para o Ensino Médio).
                    </p>
                  </Text>
                </div>
              </AccordionItem>
              <AccordionItem
                dataTestId='accord-access'
                onHeaderClick={() => {}}
                title='Quando posso começar a usar a plataforma?'
                iconDirection='right'
              >
                <div className={styles['accordion-item']}>
                  <Text secondary size={isMobile ? 't3' : 't2'}>
                    <p>
                      Com o modelo Freemium você terá acesso imediato à parte do conteúdo e de
                      algumas metodologias de personalização do ensino disponíveis na plataforma.
                    </p>
                    <br />
                    <p>
                      Contratando o modelo Premium, assim que o pagamento do cartão de crédito for
                      reconhecido pelo banco e o acesso for liberado, poderá fazer uso de todos os
                      conteúdos, das trilhas personalizadas, da função revisar “só o que errei” e
                      contar com a tecnologia do algoritmo Gênio para otimizar seus estudos e deixar
                      sua aprendizagem mais eficiente, sem falar da diversão com as nossas
                      atividades, explorando vários conteúdos de forma mais moderna, completa e
                      personalizada.
                    </p>
                  </Text>
                </div>
              </AccordionItem>
              <AccordionItem
                dataTestId='accord-plans'
                onHeaderClick={() => {}}
                title='Quais as opções de planos do modelo Premium?'
                iconDirection='right'
              >
                <div className={styles['accordion-item']}>
                  <Text secondary size={isMobile ? 't3' : 't2'}>
                    Durante o período de {year}, os planos disponíveis são:
                    <br />
                    <br />
                    <p>• Trimestral: 3x de R$ 29,90 </p>
                    <p>• Anual: 12x de R$ 24,90 (mais econômico)</p>
                  </Text>
                </div>
              </AccordionItem>
            </Accordion>
          </div>
          <SectionTryIt user={user} term={term} student={student} />
        </Row>
      </Row>
    </GridContainer>
  )
}

Doubts.propTypes = {
  user: PropTypes.object,
  term: PropTypes.string,
  student: PropTypes.bool,
}

export default Doubts

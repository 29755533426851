import React from 'react'
import PropTypes from 'prop-types'

import { Dialog } from '@plurall/elo-beta'

import styles from './MaterialDialog.module.scss'

const renderImg = url => (
  <img className={styles.content} src={url} alt='Imagem dialog' data-test-id='image-dialog' />
)

const MaterialDialog = ({ title, isImg, url, onClose }) => {
  const renderIframe = () => (
    <iframe
      className={styles.content}
      width='100%'
      height={400}
      src={url}
      title={title}
      frameBorder='0'
    />
  )

  return (
    <Dialog title={title} onClose={onClose}>
      {isImg ? renderImg(url) : renderIframe()}
    </Dialog>
  )
}
MaterialDialog.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  isImg: PropTypes.bool,
  url: PropTypes.string,
}

export default MaterialDialog

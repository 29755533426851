import { useState, useEffect } from 'react'
import { getUserService } from 'services'
import { serviceCode } from 'constants/index'

const useActiveUser = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [isActiveUser, setActiveUser] = useState(null)

  useEffect(() => {
    if (isFetching) {
      const fetchActiveUser = async () => {
        const { data: { person } } = await getUserService()

        const haveClassService = person?.services?.some(
          service => +service.id === serviceCode.ADAPTA_CODE,
        )

        const [usedAccessCode] = person?.usedAccessCodes
        const haveAccessCodeService = usedAccessCode?.services?.length > 0
        const userService = haveClassService || haveAccessCodeService

        setActiveUser(userService)
        setIsFetching(false)
      }
      fetchActiveUser()
    }
  }, [isFetching, setActiveUser])

  return { isFetching, isActiveUser }
}

export default useActiveUser

import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@plurall/elo-beta'

import styles from './EllipseIcon.module.scss'

const EllipseIcon = ({ icon }) => (
  <div className={styles.icon}>
    <Icon type={icon} variant='brand' />
  </div>
)

EllipseIcon.propTypes = {
  icon: PropTypes.string,
}

export default EllipseIcon

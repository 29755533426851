import PlurallClient from '@plurall/client'
import { getToken } from 'utils'

const Client = () => {
  try {
    const client = new PlurallClient({
      accessToken: getToken(),
      applicationId: process.env.REACT_APP_PLURALL_ID_APPLICATION,
      apiURL: process.env.REACT_APP_PLURALL_API_URL,
      disableAutoCamelize: false,
      graphqlURL: process.env.REACT_APP_SOMOS_GRAPHQL_URL,
    })
    return client
  } catch (error) {
    return null
  }
}

export default Client

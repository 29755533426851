import React from 'react'
import PropTypes from 'prop-types'
import { Card, Heading, Text } from '@plurall/elo-beta'

import styles from './EducationalSolutionsCard.module.scss'

const EducationalSolutionsCard = ({ logoImg, title, subtitle, footerItems, link }) => {
  const handleRedirect = () => {
    window.open(link, '_self')
  }

  return (
    <div className={styles.container} onClick={() => handleRedirect()}>
      <Card>
        <div className={styles.content}>
          <div className={styles.header}>
            <img src={logoImg} alt='Imagem da logo do card' />
          </div>
          <div className={styles.details}>
            <Heading element='h5' noParagraph>
              {title}
            </Heading>
            <div className={styles['details-text']}>
              <Text size='t3' alignment='center' dimmed>
                {subtitle}
              </Text>
            </div>
          </div>
          <footer className={styles.footer}>
            {footerItems.map((item, index) => (
              <div
                key={item.id}
                className={index > 1 ? styles['more-two-items'] : styles['footer-item']}
              >
                <Text size='t5' dimmed>
                  {item.name}
                </Text>
              </div>
            ))}
          </footer>
        </div>
      </Card>
    </div>
  )
}

EducationalSolutionsCard.propTypes = {
  logoImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  footerItems: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
}

export default EducationalSolutionsCard

import React from 'react'
import { Heading, theme } from '@plurall/elo-beta'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'

import { FremiumButton, UseTerms } from 'components'

import styles from './TryItForFree.module.scss'
import { trackingEventNames } from '../../constants/tracking'

const TryItForFree = ({ user, content, isSectionTwo, term, student, schoolStaff }) => {
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const classContainer = isMobile && isSectionTwo ? styles['container-2'] : styles.container
  const classContent = !isSectionTwo ? styles.content : styles['content-2']

  return (
    <section className={classContainer}>
      <div className={styles.item}>
        <div className={classContent}>
          <Heading element='h2' noParagraph textAlign={isMobile ? 'center' : 'start'}>
            {content}
          </Heading>
        </div>

        <div className={isSectionTwo ? styles['try-it-2'] : styles['try-it']}>
          <FremiumButton
            tracker={trackingEventNames.TryItButton.sectionTryItForFreeClick}
            user={user}
            term={term}
            sectionTryForIt
          />

          {student || (schoolStaff && <UseTerms isBanner />)}
        </div>
      </div>
    </section>
  )
}

TryItForFree.propTypes = {
  content: PropTypes.string,
  user: PropTypes.object,
  isSectionTwo: PropTypes.bool,
  term: PropTypes.string,
  student: PropTypes.bool,
  schoolStaff: PropTypes.bool,
}

export default TryItForFree

import React from 'react'
import { Text, Heading, Card } from '@plurall/elo-beta'
import PropTypes from 'prop-types'

import styles from './FeedbackCard.module.scss'

const FeedbackCard = ({ content, author }) => (
  <div className={styles.container}>
    <div className={styles.circle}>
      <Heading element='h1'>
        <span className={styles['circle-text']}>“</span>
      </Heading>
    </div>
    <Card className={styles.card}>
      <div className={styles['card-content']}>
        <Text size='t3'>{content}</Text>
      </div>
      <div className={styles['author-content']}>
        <div className={styles['author-text']}>
          <Text size='t4' bold>
            {author}
          </Text>
        </div>
      </div>
    </Card>
  </div>
  )

FeedbackCard.propTypes = {
  content: PropTypes.string,
  author: PropTypes.string,
}

export default FeedbackCard

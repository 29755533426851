import React, { useState } from 'react'
import { Button, Heading, Text, theme } from '@plurall/elo-beta'
import { useMediaQuery } from 'react-responsive'

import { MATERIALS_URL } from 'constants/mocks'
import { EllipseIcon, MaterialDialog } from 'components'

import { imgCertificadoPlurallzinho, imgCertificadoMobile } from '../../assets/images'
import styles from './AdaptaCertification.module.scss'

const AdaptaCertification = () => {
  const [showMaterialDialog, setShowMaterialDialog] = useState(false)
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const handleMaterialDialog = () => {
    setShowMaterialDialog(!showMaterialDialog)
  }

  const handleCertification = () => {
    window.open(MATERIALS_URL.NeuroscienceEducationCourse)
  }

  return (
    <div className={styles.container}>
      {showMaterialDialog && (
        <MaterialDialog
          title='Curso de Neurociência na Educação'
          url={MATERIALS_URL.NeuroscienceEducationVideo}
          onClose={handleMaterialDialog}
        />
      )}
      <div className={styles.header}>
        <EllipseIcon icon='badge-check' />
        <div className={styles['header-title']}>
          <Heading element='h1' noParagraph>
            Certificação Adapta
          </Heading>
        </div>
      </div>
      <div className={styles.content}>
        <Text size='t2' alignment='center'>
          O curso de Neurociência na Educação, ajuda a entender como a neurociência aplicada à
          educação funciona e fornece exemplos de como aplicar em sala de aula.
        </Text>
      </div>
      <div className={styles.action}>
        <div className={styles['action-primary']}>
          <Button dataTestId='button-certification' onClick={handleCertification} size='large'>
            Obtenha seu certificado
          </Button>
        </div>
        <Button
          dataTestId='button-certification-more-about-course'
          onClick={handleMaterialDialog}
          icon='Video-lesson'
          size='small'
          minimal
        >
          Saiba mais sobre o curso
        </Button>
      </div>
      <img
        width={isMobile ? 292 : 914}
        loading='lazy'
        src={isMobile ? imgCertificadoMobile : imgCertificadoPlurallzinho}
        alt='Imagem da sessão dos professores'
        className={styles['img-section-certification']}
        data-test-id='image-section-certification'
      />
    </div>
  )
}

export default AdaptaCertification

import React from 'react'
import PropTypes from 'prop-types'

import { Heading, Text } from '@plurall/elo-beta'

import styles from './ErrorMessage.module.scss'

const ErrorMessage = ({ src, subTitle, title }) => (
  <div className={styles['no-results']}>
    {src && <img data-test-id='image-error' src={src} alt={`${title}-${subTitle}`} />}
    <div className={styles['title-wrapper']}>
      <Heading element='h5' textAlign='center'>
        {title}
      </Heading>
    </div>
    {subTitle && (
      <Text alignment='center' dimmed size='t3'>
        {subTitle}
      </Text>
    )}
  </div>
)

ErrorMessage.defaultProps = {
  src: `${process.env.REACT_APP_ASSETS_URL}/static/assets/images/tired.svg`,
  title: 'Corremos e voamos, mas não encontramos o que você busca.',
}

ErrorMessage.propTypes = {
  src: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
}

export default ErrorMessage

const contentForSchoolStaff = [
  {
    numberCircle: 1,
    url: 'createCustomClasses',
    title: 'Crie turmas personalizadas',
    content: `Personalize suas turmas de forma
              individualizada, utilizando recursos
              de inteligência artificial.`,
  },
  {
    numberCircle: 2,
    url: 'createContentTrails',
    title: 'Crie trilhas dos conteúdos',
    content: `Aumente o engajamento de seus
              alunos utilizando cronogramas de
              estudos personalizados.`,
  },
  {
    numberCircle: 3,
    url: 'followYourClasses',
    title: 'Acompanhe suas turmas',
    content: `Aumente a performance de seus
              alunos utilizando o relatório
              individualizado de cada aluno.`,
  },
]

export { contentForSchoolStaff }

import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@plurall/elo-beta'

import styles from './UseTerms.module.scss'

const UseTerms = ({ isBanner }) => {
  const classText = isBanner && styles['is-banner']

  return (
    <Text size='t3' secondary alignment='center'>
      <span className={classText}>
        Ao acessar, concordo com os &nbsp;
        <Text size='t3' element='a' noUnderline href='https://www.plurall.net/store-termos-de-uso'>
          <span className={classText}>Termos de uso</span>
        </Text>
        &nbsp; do Plurall Adapta.
      </span>
    </Text>
  )
}

UseTerms.propTypes = {
  isBanner: PropTypes.bool,
}

export default UseTerms

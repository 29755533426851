import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Text } from '@plurall/elo-beta'

import { useFreemiumAccess } from 'hooks'
import { FREE_ID } from 'constants/mocks'
import { trackingEventNames } from 'constants/tracking'

import PlansCard from 'components/PlansCard/PlansCard'
import { useResponsibleDialog } from 'contexts/ResponsibleDialogContext'

import styles from './Plans.module.scss'

const Plans = ({ plans, user, term }) => {
  const ref = useRef()

  const [cardSelected, setCardSelected] = useState(2)
  const { openResponsibleDialog } = useResponsibleDialog()
  const { freemiumAccess } = useFreemiumAccess({ openResponsibleDialog })
  const history = useHistory()

  useEffect(() => {
    if (plans.length > 0) {
      /* ref.current.scrollLeft = 1000 */
      ref.current.scrollLeft = 292
    }
  }, [plans])

  const handleSignPlan = async (planSelected, tag) => {
    const paramPlan = planSelected.id === '3' ? 'anual' : 'trimestral'

    handleAccessOrSignTracker(planSelected)

    if (planSelected.id === FREE_ID) {
      freemiumAccess({ user, term })
    } else {
      history.push(`/adapta/checkout/${paramPlan}`, { plans, planSelected, tag })
    }
  }

  const handleAccessOrSignTracker = planSelected => {
    const { freePlanClick, quarterlyPlanClick, annualPlanClick } =
      trackingEventNames.AccessOrToSignButton

    let tracker

    if (planSelected.id === '1') tracker = freePlanClick
    else if (planSelected.id === '2') tracker = quarterlyPlanClick
    else tracker = annualPlanClick

    window.PLURALL_TRACKER.track(tracker)
  }

  const handleDataTestId = planId => {
    if (planId === '1') {
      return 'access-free'
    } else if (planId === '2') {
      return 'access-premium-quarterly'
    }
    return 'access-premium-annual'
  }

  return (
    <section className={styles.container} id='choose-your-plan'>
      <div className={styles['section-plans-card']} ref={ref}>
        {plans.map((plan, index) => (
          <PlansCard
            key={plan.id}
            freePlan={plan.id === '1'}
            planName={plan.title}
            planAmount={plan.value}
            handleSignPlan={() => handleSignPlan(plan, plan.tag)}
            accessDays={plan.accessDays}
            index={index}
            cardSelected={cardSelected}
            setCardSelected={setCardSelected}
            dataTestId={handleDataTestId(plan.id)}
            tag={plan.tag}
            moreEconomical={plan.moreEconomical}
            coverages={plan.coverages}
          />
        ))}
      </div>
      <div className={styles['section-footer']}>
        <Text size='t4' secondary alignment='center'>
          Ao acessar, concordo com os &nbsp;
          <Text
            size='t4'
            element='a'
            noUnderline
            href='https://www.plurall.net/store-termos-de-uso'
          >
            Termos de uso
          </Text>
          &nbsp; do Plurall Adapta.
        </Text>

        <Text size='t4' alignment='center'>
          A assinatura pode ser cancelada a qualquer momento, seguindo a nossa &nbsp;
          <Text
            size='t4'
            element='a'
            href='https://www.plurall.net/store-politica-de-cancelamento'
            target='_blank'
            noUnderline
          >
            Política de cancelamento.
          </Text>
        </Text>
      </div>
    </section>
  )
}

Plans.propTypes = {
  plans: PropTypes.array.isRequired,
  user: PropTypes.object,
  term: PropTypes.string,
}

export default Plans

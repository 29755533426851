import { imgMeuProf, imgAdapta } from '../assets/images'

export const SUBSCRIPTION_PLAN_ID = '1'

export const FREE_ID = '1'

export const SERVICE_ID = 2

export const POSITION_STATUS_ID = 1

export const OPTIONS_DATE_MASK = { month: '2-digit', day: '2-digit', year: 'numeric' }

export const DEFAULT_API_ERROR_MESSAGE =
  'Ops! Ocorreu um erro. Caso o erro persista, entre em contato com o suporte.'

export const PLANS = {
  trimestral: 'trimestral',
  anual: 'anual',
}

export const TYPE_PLAN = {
  premium: 'Premium',
  fremium: 'Fremium',
}

export const STATUS_SERVICE = {
  active: 'SUBSCRIPTION_ACTIVE',
  inactive: 'SUBSCRIPTION_INACTIVE',
}

export const ORDER_STATUS = [
  { id: 1, name: 'Pagamento realizado', date: '07/07/2012 às 10:31', success: true },
  { id: 2, name: 'Pedido recebido', date: '07/07/2012 às 10:32', success: true },
  { id: 3, name: 'Acesso liberado', date: '07/07/2012 às 10:32', success: false },
]

export const CARDS = [
  {
    id: 1,
    logoImg: imgMeuProf,
    title: 'Plurall Meu Prof',
    subtitle:
      'Aprenda inglês em um mundo virtual gamificado de uma maneira lúdica, adaptativa e acessível.',
    footerItems: [
      { id: 1, name: 'FUNDAMENTAL I' },
      { id: 2, name: 'FUNDAMENTAL II' },
      { id: 3, name: 'ENSINO MÉDIO' },
      { id: 4, name: 'PRÉ-VESTIBULAR' },
    ],
    link: process.env.REACT_APP_LINK_PLURALL,
  },
  {
    id: 2,
    logoImg: imgAdapta,
    title: 'Plurall Adapta by Seneca',
    subtitle:
      'Impulsione o aprendizado de forma mais divertida, rápida e eficiente utilizando metodologias ativas, recursos de inteligência artificial e neuroaprendizagem.',
    footerItems: [
      { id: 1, name: 'FUNDAMENTAL II' },
      { id: 2, name: 'ENSINO MÉDIO' },
      { id: 3, name: 'PRÉ-VESTIBULAR' },
    ],
    link: `${process.env.REACT_APP_HOST}/adapta`,
  },
]

export const PAYMENT_STATUS = {
  NO_STATUS: 'NO_STATUS',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  PAYMENT_FINALIZED: 'PAYMENT_FINALIZED',
  PAYMENT_NOT_FINALIZED: 'PAYMENT_NOT_FINALIZED',
}

export const BANNER_IMG_NAMES = {
  student: {
    desktop: 'student-desktop-1200px',
    tablet: 'student-tablet-1024px',
    mobile: 'student-mobile-480px',
  },
  teacher: {
    desktop: 'teacher-desktop-1200px',
    tablet: 'teacher-tablet-1024px',
    mobile: 'teacher-mobile-480px',
  },
  'school-staff': {
    desktop: 'school-staff-desktop-1200px',
    tablet: 'school-staff-tablet-1024px',
    mobile: 'school-staff-mobile-480px',
  },
  'school-staff-campaign': {
    desktop: 'school-staff-campaign-desktop-1200px',
    tablet: 'school-staff-campaign-tablet-1024px',
    mobile: 'school-staff-campaign-mobile-480px',
    alt: 'Banner da campanha Plu-Masters',
    url: 'https://storefamily.plurall.net/plumasters',
  },
  other: {
    desktop: 'school-desktop-1200px',
    tablet: 'school-tablet-1024px',
    mobile: 'school-mobile-480px',
  },
  register: {
    desktop: 'student-register-desktop-1200px',
    tablet: 'student-register-tablet-1024px',
    mobile: 'student-register-mobile-480px',
  },
}

export const MATERIALS_URL = {
  NeuroscienceEducationVideo:
    'https://www.youtube.com/embed/z0qrv_jjYyA?list=PLtW5dT7Wu-GHzCBAD80ubxZ8PTg5H_JHm&autoplay=1&modestbranding=1',
  NeuroscienceEducationCourse:
    'https://adapta.plurall.net/dashboard/courses/add?Etapa=Capacita%C3%A7%C3%A3o+Profissional&utm_source=home&utm_medium=banner&utm_campaign=adapta_teacher_21-03',
  knowMoreAbout: 'https://www.youtube.com/embed/lm78HeUIrCg?autoplay=1&modestbranding=1',
  createCustomClasses: 'https://www.youtube.com/embed/OgSFEewdpww?autoplay=1&modestbranding=1',
  createContentTrails: `${process.env.REACT_APP_ASSETS_URL}/static/assets/images/plurall-store/create-trails.gif`,
  followYourClasses: `${process.env.REACT_APP_ASSETS_URL}/static/assets/images/plurall-store/follow-your-classes.gif`,
}

export const ROLE_TEACHER_ID = 3
export const ROLE_COORDINATOR_ID = 4
export const ROLE_STUDENT_ID = 6
export const ROLE_PEDAGOGICAL_ADVISOR = 19

export const SCHOOL_STAFF = [ROLE_COORDINATOR_ID, ROLE_PEDAGOGICAL_ADVISOR, ROLE_TEACHER_ID]

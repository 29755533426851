import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { getProfile } from 'services'
import { UserContext } from 'contexts'
import { POSITION_STATUS_ID } from 'constants/mocks'

const UserProvider = ({ children }) => {
  const [user, setUser] = useState()

  useEffect(() => {
    const gettingProfile = async () => {
      const { data } = await getProfile(POSITION_STATUS_ID)

      if (data) setUser({ user: data.person })
    }

    gettingProfile()
  }, [])

  return <UserContext.Provider value={{ ...user }}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  children: PropTypes.node,
}

export default UserProvider

import React from 'react'
import { Text } from '@plurall/elo-beta'

import { EllipseIcon } from 'components'
import { benefits } from './benefits'
import styles from './UnderstandBenefits.module.scss'

const BenefitCards = () => (
  <div className={styles.benefits}>
    {benefits.map(benefit => (
      <div key={benefit.title} className={styles['benefits-card']}>
        <EllipseIcon icon={benefit.icon} />
        <div className={styles['benefits-card-title']}>
          <Text size='t2' semibold>
            {benefit.title}
          </Text>
        </div>
        <Text size='t4' alignment='center'>
          {benefit.description}
        </Text>
      </div>
    ))}
  </div>
)

export { BenefitCards }

import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@plurall/elo-beta'

import { useFreemiumAccess } from 'hooks'
import { useResponsibleDialog } from 'contexts/ResponsibleDialogContext'
import { hasSchoolStaff, hasRole } from 'utils/common'
import { ROLE_STUDENT_ID } from 'constants/mocks'

import styles from './FremiumButton.module.scss'

const FremiumButton = ({ user, term, sectionTryForIt }) => {
  const { openResponsibleDialog } = useResponsibleDialog()
  const { freemiumAccess, loadingRedirect } = useFreemiumAccess({ openResponsibleDialog })

  const schoolStaffOrStudent = hasSchoolStaff(user) || hasRole(ROLE_STUDENT_ID, user)

  return (
    <div className={styles['try-it-for-free']}>
      <Button
        dataTestId='button-experimentar-gratis'
        fullWidth
        onClick={() => freemiumAccess({ user, term })}
        highlight={schoolStaffOrStudent}
        size='large'
        disabled={!schoolStaffOrStudent}
        loading={loadingRedirect}
      >
        {schoolStaffOrStudent ? <span>Experimentar grátis</span> : <span>Acesso indisponível</span>}
      </Button>

      <div
        className={styles['section-doubts-link']}
        style={{ display: schoolStaffOrStudent ? 'none' : 'flex' }}
      >
        <a
          href='https://ajuda.plurall.net/hc/pt-br'
          className={sectionTryForIt ? styles['doubts-link-try-it'] : styles['doubts-link']}
        >
          Dúvidas? Acesse nossa área de Ajuda
        </a>
      </div>
    </div>
  )
}

FremiumButton.propTypes = {
  user: PropTypes.object,
  sectionTryForIt: PropTypes.bool,
  term: PropTypes.string,
}

export default FremiumButton

import React, { useEffect, useState, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useParams } from 'react-router-dom'
import { getTerm, acceptanceTerm, mutationOrder, querySubscriptionPlans } from 'services'
import { UserContext } from 'contexts'

import {
  theme,
  Heading,
  Text,
  Grid,
  Row,
  Input,
  Checkbox,
  Button,
  InputDropdown,
} from '@plurall/elo-beta'

import WidgetResponsibles from '@plurall/widget-responsibles'

import { PaymentMethodsCard, SubscriptionCard, ResumeCard } from 'components'

import { DEFAULT_API_ERROR_MESSAGE, PLANS, SERVICE_ID } from 'constants/mocks'
import { trackingEventNames } from 'constants/tracking'

import styles from './Checkout.module.scss'

const Checkout = () => {
  const { user } = useContext(UserContext)

  const { state } = useLocation()
  const { plan } = useParams()

  const [schoolSelected, setSchoolSelected] = useState()
  const [schoolsList, setSchoolsList] = useState([])
  const [termChecked, setTermChecked] = useState(false)
  const [paymentButtonClicked, setPaymentButtonClicked] = useState(false)
  const [substitutePlans, setSubstitutePlans] = useState()
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(state ? state.planSelected.id : '2')
  const [responsible, setResponsible] = useState()
  const [term, setTerm] = useState()
  const [tag, setTag] = useState(state && state.tag)
  const [hasError, setHasError] = useState(false)

  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const userRoles = user !== undefined && user.roles[0]

  useEffect(() => {
    // adicionar tag aqui quando necessário
    setTag('')

    const getSubscriptionPlans = async () => {
      const response = await querySubscriptionPlans(SERVICE_ID)

      setSubstitutePlans(response)

      if (plan === PLANS.trimestral) {
        setSubscriptionPlanId(response.plans[1].id.toString())
      } else {
        setSubscriptionPlanId(response.plans[2].id.toString())
      }
    }

    getSubscriptionPlans()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (user && user.schools.length > 0) {
      const schools = user.schools.map(school => ({
        id: school.id,
        checked: false,
        label: school.legalName,
      }))

      if (schools.length === 1) {
        handleChangeSchool(schools[0])
      }

      setSchoolsList(schools)
    }
  }, [user])

  useEffect(() => {
    const gettingTerm = async () => {
      const {
        data: { code },
      } = await getTerm()

      if (code) {
        setTerm(code)
      }
    }

    gettingTerm()
  }, [])

  const handleChangeSchool = value => {
    setSchoolSelected(value.id)
  }

  const handleGoToPayment = async () => {
    window.PLURALL_TRACKER.track(trackingEventNames.goToPaymentClick)
    setHasError(false)

    if (!termChecked) {
      return setPaymentButtonClicked(true)
    }

    if (termChecked && responsible) {
      await acceptanceTerm({
        origin: 'plurallid',
        code: term,
        userId: Number(user?.id),
      })

      const { data, errors } = await mutationOrder(subscriptionPlanId, schoolSelected, responsible)

      if (errors) {
        setHasError(true)
        return false
      }

      if (data) {
        setPaymentButtonClicked(true)
        const { checkoutUrl } = data.getCheckout.result
        window.open(checkoutUrl, '_self')
      }

      return true
    }

    return false
  }

  const handleChangeChecked = () => {
    setPaymentButtonClicked(false)
    setTermChecked(!termChecked)
  }

  return (
    <Row>
      <Grid desktop={6} tablet={12} mobile={12}>
        <div className={styles.header}>
          <Heading element='h1' noParagraph bold>
            Assinatura da solução
          </Heading>
          <Text size='t3' shy semibold>
            Confirme aqui as informações da sua compra.
          </Text>
        </div>

        {(state || substitutePlans) && (
          <SubscriptionCard
            plans={state ? state.plans : substitutePlans.plans}
            subscriptionPlanId={subscriptionPlanId}
            setSubscriptionPlanId={setSubscriptionPlanId}
            tag={tag}
          />
        )}

        <div className={styles.form}>
          <div>
            <Heading element='h5' dataTestId='username'>
              {userRoles.name}
            </Heading>
            <Input type='text' readOnly label='Nome Completo' value={user && user.name} />
          </div>
          <div>
            <>
              {user && user.schools.length > 1 && (
                <div>
                  <Heading element='h5' noParagraph={isMobile}>
                    Escola
                  </Heading>
                  <Text dimmed size={isMobile ? 't4' : 't3'}>
                    Você possui mais de uma escola em seu cadastro.
                  </Text>
                  <br />
                  <br />
                  <InputDropdown
                    items={schoolsList}
                    label='Selecione uma escola'
                    onChange={value => handleChangeSchool(value)}
                  />
                </div>
              )}

              {user && (
                <WidgetResponsibles
                  callback={data => {
                    setResponsible(data)
                  }}
                  userID={user.id}
                />
              )}
            </>

            <Checkbox
              type='checkbox'
              onChange={() => handleChangeChecked()}
              checked={termChecked}
              dataTestId='terms-of-use'
              label={
                <Text size='t3'>
                  Concordo com os&nbsp;
                  <Text
                    dimmed
                    element='a'
                    href='https://www.plurall.net/store-termos-de-uso'
                    target='_blank'
                    size='t3'
                  >
                    Termos de uso
                  </Text>
                  &nbsp; do Plurall Adapta.
                </Text>
              }
              name='agree-checkbox'
              negative={!termChecked && paymentButtonClicked}
              textValidation='Você precisa concordar com os termos para continuar.'
            />

            {hasError && (
              <div className={styles['error-message']}>
                <Text size='t3' variant='negative'>
                  {DEFAULT_API_ERROR_MESSAGE}
                </Text>
              </div>
            )}

            <div className={styles['section-payment-button']}>
              <Button
                onClick={handleGoToPayment}
                disabled={!responsible || paymentButtonClicked || !schoolSelected}
              >
                Ir para pagamento
              </Button>
            </div>
          </div>
        </div>
      </Grid>

      <Grid desktop={6} tablet={12} mobile={12}>
        <div className={styles['right-section']}>
          {(state || substitutePlans) && (
            <ResumeCard
              plans={state ? state.plans : substitutePlans.plans}
              subscriptionPlanId={subscriptionPlanId}
            />
          )}

          <PaymentMethodsCard />
        </div>
      </Grid>
    </Row>
  )
}

export default Checkout

import { acceptanceTerm } from 'services'
import { trackingEventNames } from 'constants/tracking'
import { SCHOOL_STAFF } from 'constants/mocks'

export const replaceAndDecimalNumber = value => {
  const newValue = value.toFixed(2)

  return newValue.replace('.', ',')
}

export const validateEmail = email => {
  const emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i

  return emailRegex.test(email)
}

export const hasRole = (roleID, user) => user?.roles?.find(role => Number(role.id) === roleID)

export const hasSchoolStaff = user => user?.roles?.find(role => SCHOOL_STAFF.includes(+role.id))

export const handleRedirectSeneca = async (user, term) => {
  setTimeout(() => {
    window.PLURALL_TRACKER.track(trackingEventNames.goToSeneca)
    window.open(process.env.REACT_APP_LINK_SENECA)
  }, 100)

  await acceptanceTerm({
    origin: 'plurallid',
    code: term,
    userId: Number(user?.id),
  })
}

import React, { useEffect, useState, useContext } from 'react'
import { queryCurrentService, getTerm, querySubscriptionPlans } from 'services'
import { Spinner } from '@plurall/elo-beta'

import {
  AdaptaCertification,
  Plans,
  ForEstudents,
  TryItForFree,
  ForSchoolStaff,
  CustomerFeedback,
  BannerNew,
  ResponsibleDialog,
  UnderstandBenefits,
} from 'components'

import { trackingEventNames } from 'constants/tracking'
import {
  ROLE_STUDENT_ID,
  SERVICE_ID,
  SCHOOL_STAFF,
  STATUS_SERVICE,
  TYPE_PLAN,
} from 'constants/mocks'

import { UserContext } from 'contexts'
import { useResponsibleDialog } from 'contexts/ResponsibleDialogContext'

import { imgMascot } from '../../assets/images'
import { Doubts, HeaderHome, SectionTryIt } from './components'
import styles from './Home.module.scss'

const Home = () => {
  const { user } = useContext(UserContext)

  const [blockedPremium, setBlockedPremium] = useState(false)
  const [term, setTerm] = useState()
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [registerFreemium, setRegisterFreemium] = useState(false)

  const { showResponsibleDialog } = useResponsibleDialog()

  // variável necessária para lidar com tags de promoções
  const [tags, setTags] = useState([]) // eslint-disable-line no-unused-vars

  useEffect(() => {
    const redirectToSeneca = () => {
      window.PLURALL_TRACKER.track(trackingEventNames.goToSeneca)
      window.open(process.env.REACT_APP_LINK_SENECA, '_self')
    }

    const fetchCurrentSubscription = async () => {
      const { data } = await queryCurrentService(SERVICE_ID)

      setBlockedPremium(data?.me?.blockedPremium)

      if (data.me.currentSubscription) {
        if (user && user.roles.some(u => +u.id === ROLE_STUDENT_ID)) {
          const { subscriptionPlan, status } = data.me.currentSubscription

          subscriptionPlan.type === TYPE_PLAN.premium && status === STATUS_SERVICE.active
            ? redirectToSeneca()
            : setRegisterFreemium(true)
        }
      }
    }

    const getSubscriptionPlans = async () => {
      const response = await querySubscriptionPlans(SERVICE_ID)
      setPlans(response.plans)
    }

    fetchCurrentSubscription()
    getSubscriptionPlans()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setRegisterFreemium, registerFreemium])

  useEffect(() => {
    const gettingTerm = async () => {
      const {
        data: { code },
      } = await getTerm()

      if (code) {
        setTerm(code)
      }
    }

    gettingTerm()
  }, [])

  useEffect(() => {
    const filteredTags = plans.map(plan => plan.tag)

    if (filteredTags.length) {
      setLoading(false)
      setTags(filteredTags)
    }
  }, [plans])

  const student = user && user.roles.some(userRole => +userRole.id === ROLE_STUDENT_ID)
  const schoolStaff = user && user.roles.some(userRole => SCHOOL_STAFF.includes(+userRole.id))

  return (
    <>
      {student && (
        <div>
          <HeaderHome registerFreemium={registerFreemium} loading={loading} />
        </div>
      )}
      <div className={styles.container}>
        {loading ? (
          <div className={styles['spinner-container']}>
            <Spinner />
          </div>
        ) : (
          <BannerNew
            user={user}
            term={term}
            registerFreemium={registerFreemium}
            schoolStaff={schoolStaff}
            student={student}
            loading={loading}
          />
        )}

        {student && !registerFreemium && (
          <div className={styles['section-students']}>
            <ForEstudents />
          </div>
        )}

        {student && !registerFreemium && (
          <TryItForFree
            content={
              student
                ? 'Potencialize seu aprendizado e desempenho escolar de forma fácil, rápida e eficiente.'
                : 'Experimente o Plurall Adapta by Seneca agora mesmo e venha aprender se divertindo.'
            }
            user={user}
          />
        )}

        {schoolStaff && (
          <div>
            <UnderstandBenefits />
            <div className={styles['section-teachers']}>
              <div className={styles['content-teachers']}>
                <ForSchoolStaff />
              </div>
            </div>

            <div className={styles['section-customer-feedback']}>
              <div className={styles['customer-feedback-content']}>
                <CustomerFeedback />
              </div>
            </div>

            {student && (
              <TryItForFree
                content='Comece agora mesmo a impulsionar o aprendizado dos seus alunos de forma fácil, rápida e eficiente.'
                user={user}
                term={term}
                isSectionTwo
                student={student}
                schoolStaff={schoolStaff}
              />
            )}
          </div>
        )}

        {!blockedPremium && student && plans.length > 0 && (
          <Plans plans={plans} user={user} term={term} registerFreemium={registerFreemium} />
        )}

        {student && registerFreemium && (
          <div className={styles['section-students']}>
            <ForEstudents />
          </div>
        )}
        {!schoolStaff && !registerFreemium && (
          <div className={styles['mascot-section-learning']}>
            <img
              src={imgMascot}
              alt='Imagem do mascote na sessão de feedback'
              className={styles['img-mascot']}
            />
          </div>
        )}
      </div>

      {schoolStaff && <AdaptaCertification />}

      {schoolStaff && (
        <SectionTryIt user={user} term={term} student={student} schoolStaff={schoolStaff} />
      )}

      {!schoolStaff && !registerFreemium && (
        <div className={styles['section-doubts']}>
          <Doubts user={user} term={term} student={student} schoolStaff={schoolStaff} />
        </div>
      )}

      {showResponsibleDialog && <ResponsibleDialog user={user} term={term} />}
    </>
  )
}

export default Home

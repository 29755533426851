import React from 'react'
import { Text } from '@plurall/elo-beta'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import styles from './SubscribeNow.module.scss'

const cx = classNames.bind(styles)

const SubscribeNow = ({ isBanner }) => {
  const subscribeClasses = cx('subscribe-now', { 'subscribe-now-banner': isBanner })

  return (
    <div className={subscribeClasses}>
      <Text alignment='center' size='t3' dimmed>
        Ou tenha acesso ilimitados a conteúdos &nbsp; exclusivos com o Plano Premium. &nbsp;
        <Text size='t3' noUnderline element='a' href='#choose-your-plan' bold>
          Assinar agora.
        </Text>
      </Text>
    </div>
  )
}

SubscribeNow.propTypes = {
  isBanner: PropTypes.bool,
}

export default SubscribeNow

import PlurallClient from '@plurall/client'
import { getToken } from 'utils'

const ClientStore = () => {
  try {
    const client = new PlurallClient({
      accessToken: getToken(),
      applicationId: process.env.REACT_APP_PLURALL_ID_APPLICATION,
      apiURL: `${process.env.REACT_APP_MS_API_URL}`,
      disableSnakeCaseRequest: true,
      graphqlURL: process.env.REACT_APP_STORE_API_URL,
    })
    return client
  } catch (error) {
    return null
  }
}

export default ClientStore

import React from 'react'
import { Heading, Text } from '@plurall/elo-beta'

import { BenefitCards } from './BenefitCards'
import styles from './UnderstandBenefits.module.scss'

const UnderstandBenefits = () => (
  <section className={styles.container}>
    <div className={styles.header}>
      <Heading element='h6' noParagraph>
        Aumente o desempenho dos seus alunos.
      </Heading>
      <div className={styles['header-tittle']}>
        <Heading element='h1' textAlign='center' noParagraph>
          Entenda os benefícios do <span className={styles.accent30}>Plurall Adapta.</span>
        </Heading>
      </div>
      <Text size='t2' alignment='center'>
        Defina tarefas marcadas automaticamente, utilize recursos de gamificação, monitore o
        progresso dos alunos e aumente as notas de suas aulas.
      </Text>
    </div>
    <BenefitCards />
  </section>
)

export default UnderstandBenefits

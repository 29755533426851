import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'

import { Button, theme, Slider } from '@plurall/elo-beta'
import { useFreemiumAccess } from 'hooks'
import { useResponsibleDialog } from 'contexts/ResponsibleDialogContext'
import { Redirect } from 'react-router-dom'
import { trackingEventNames } from 'constants/tracking'
import { MATERIALS_URL } from 'constants/mocks'
import { MaterialDialog } from 'components'
import Slide from './Slide'

import styles from './BannerNew.module.scss'

const BannerNew = ({ loading, registerFreemium, schoolStaff, student, term, user }) => {
  const [redirectUrl, setRedirectUrl] = useState()
  const [showMaterialDialog, setShowMaterialDialog] = useState(false)

  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const { openResponsibleDialog } = useResponsibleDialog()
  const { freemiumAccess } = useFreemiumAccess({ openResponsibleDialog })

  const handleAnualPlan = () => {
    window.PLURALL_TRACKER.track(trackingEventNames.Banner.regiterStudent)
    setRedirectUrl('/adapta/checkout/anual')
  }

  const openHelpCenter = () => {
    window.open('https://ajuda.plurall.net/hc/pt-br')
  }

  const handleRole = () => {
    if (schoolStaff) return 'school-staff'

    if (student && !registerFreemium) return 'student'

    if (registerFreemium) return 'register'

    return 'other'
  }

  const handleMaterialDialog = event => {
    // previne acionar evento do slide ao clicar em um item interno a ele
    if (event) {
      event.stopPropagation()
    }
    setShowMaterialDialog(!showMaterialDialog)
  }

  const handleBannerClick = () => {
    if (role === 'other') {
      openHelpCenter()
      return
    }
    if (registerFreemium) {
      handleAnualPlan()
      return
    }
    freemiumAccess({ user, term })
  }

  if (redirectUrl) {
    return <Redirect push to={redirectUrl} />
  }

  const LearnMoreButton = () => (
    <div className={styles['container-button']}>
      <Button
        dataTestId='button-banner-more-about-adapta'
        onClick={handleMaterialDialog}
        icon='Video-lesson'
        inverted={!isMobile}
        minimal={!isMobile}
        secondary={isMobile}
      >
        Saiba mais sobre o Adapta
      </Button>
    </div>
  )

  const role = handleRole()

  return (
    <>
      {showMaterialDialog && (
        <MaterialDialog
          title='Conheça o Plurall Adapta'
          url={MATERIALS_URL.knowMoreAbout}
          onClose={handleMaterialDialog}
        />
      )}
      {!loading && (
        <div className={styles.container}>
          {!schoolStaff && !registerFreemium && (
            <Slider dataTestId='slider' onSlideClick={handleBannerClick}>
              <Slide image={role} dataTestId='slide-student' />
            </Slider>
          )}
          {schoolStaff && (
            <Slider dataTestId='slider' roll duration={8000} onSlideClick={handleBannerClick}>
              <div>
                <Slide image={role} dataTestId='slide-school-staff-1' />
                {!isMobile && <LearnMoreButton />}
              </div>
            </Slider>
          )}
        </div>
      )}
      {isMobile && <LearnMoreButton />}
    </>
  )
}

BannerNew.propTypes = {
  user: PropTypes.object,
  term: PropTypes.string,
  registerFreemium: PropTypes.bool,
  schoolStaff: PropTypes.bool,
  student: PropTypes.bool,
  loading: PropTypes.bool,
}

export default BannerNew

import React from 'react'
import PropTypes from 'prop-types'
import { Heading, Text, theme } from '@plurall/elo-beta'
import { useMediaQuery } from 'react-responsive'

import styles from './ContentForSchoolStaff.module.scss'

const ContentForSchoolStaff = ({ content, handleContentSelected, numberCircle, title, url }) => {
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  return (
    <div className={styles['section-content']}>
      <div className={styles.item}>
        <div className={styles.circle}>
          <Heading element='h3' noParagraph>
            <span className={styles['circle-text']}>{numberCircle}</span>
          </Heading>
        </div>
        <div className={styles.topics}>
          <Text size='t2' bold>
            {title}
          </Text>
          <div className={styles['content-item']}>
            <Text size={isMobile ? 't3' : 't2'}>{content}</Text>
            <Text
              size={isMobile ? 't3' : 't2'}
              element='a'
              onClick={() => handleContentSelected({ title, url })}
              secondary
            >
              Saiba Mais
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

ContentForSchoolStaff.propTypes = {
  content: PropTypes.string,
  handleContentSelected: PropTypes.func,
  numberCircle: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default ContentForSchoolStaff

import PropTypes from 'prop-types'
import React from 'react'

import { Grid, Row } from '@plurall/elo-beta'
import { Layout as LayoutElo } from '@plurall/layout'
import { getToken } from 'utils'
import { ErrorBoundary } from 'components'

const Layout = ({ children, location }) => {
  const menuSubItem = location?.pathname.includes('minhas-assinaturas') ? 'assignment' : ''

  return (
    <LayoutElo
      apiURL={process.env.REACT_APP_PLURALL_API_URL}
      applicationId={process.env.REACT_APP_PLURALL_ID_APPLICATION}
      graphqlURL={process.env.REACT_APP_SOMOS_GRAPHQL_URL}
      userToken={getToken()}
      service='adapta'
      menuSubItem={menuSubItem}
      projectName='Adapta'
      plurallLogo
      menuURL={`${process.env.REACT_APP_PLURALL_MENU_API_URL}/store`}
      msApiURL={process.env.REACT_APP_MS_API_URL}
      apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
      authDomain={process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}
      projectId={process.env.REACT_APP_FIREBASE_PROJECT_ID}
      storageBucket={process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}
      messagingSenderId={process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}
      appId={process.env.REACT_APP_FIREBASE_APP_ID}
      measurementId={process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}
    >
      <ErrorBoundary>
        <Row>
          <Grid desktop={12} tablet={12}>
            {children}
          </Grid>
        </Row>
      </ErrorBoundary>
    </LayoutElo>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

export default Layout

import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@plurall/elo-beta'

import { FremiumButton, UseTerms } from 'components'
import { SCHOOL_STAFF } from 'constants/mocks'
import { trackingEventNames } from 'constants/tracking'

import styles from './SectionTryIt.module.scss'

const SectionTryIt = ({ user, term, student }) => {
  const schoolStaff = user && user.roles.some(userRole => SCHOOL_STAFF.includes(+userRole.id))

  return (
    <div className={styles['section-try-it']}>
      <span className={styles.title}>
        {!schoolStaff && (
          <Heading dataTestId='try-it-header' element='h2' noParagraph textAlign='center'>
            Acesse o Plurall Adapta by Seneca agora mesmo e venha aprender se divertindo.
          </Heading>
        )}
        {schoolStaff && (
          <>
            <div className={styles['title-school-staff']}>
              <Heading dataTestId='try-it-header' element='h3' textAlign='center' noParagraph>
                Experimente o <span className={styles.accent30}>Plurall Adapta</span> by Seneca e
                comece agora mesmo a impulsionar o aprendizado dos seus alunos.
              </Heading>
            </div>
          </>
        )}
      </span>
      <div className={styles['section-try-button']}>
        <div data-test-id='try-it-button' className={styles['try-it-button']}>
          <FremiumButton
            tracker={trackingEventNames.TryItButton.sectionDoubtsClick}
            user={user}
            term={term}
            sectionTryForIt
          />
        </div>

        <div data-test-id='use-terms'>{(student || schoolStaff) && <UseTerms />}</div>
      </div>
    </div>
  )
}

SectionTryIt.propTypes = {
  user: PropTypes.object,
  term: PropTypes.string,
  student: PropTypes.bool,
}

export default SectionTryIt

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Heading, Divider, Text, Grid, Row, Banner } from '@plurall/elo-beta'

import { getFormattedDate } from 'utils'
import { queryOrderStatus } from 'services'
import { ORDER_STATUS, OPTIONS_DATE_MASK } from 'constants/mocks'
import { trackingEventNames } from 'constants/tracking'
import { ErrorMessage } from 'components'

import { BreadcrumbOrder, SubscriptionCard, PromotionalCard } from './Components'

import styles from './OrderStatus.module.scss'

const OrderStatus = () => {
  const { subscriptionId } = useParams()

  const [error, setError] = useState()
  const [order, setOrder] = useState()

  useEffect(() => {
    const gettingOrderStatus = async () => {
      const variables = { id: subscriptionId }
      const { result, errors } = await queryOrderStatus(variables)

      if (errors?.length > 0) setError(errors[0])

      if (result) setOrder(result)
    }

    gettingOrderStatus()
  }, [subscriptionId])

  const handleHelpCenter = () => {
    window.PLURALL_TRACKER.track(trackingEventNames.helpCenterClick)

    window.PLURALL_ELO.openCentralAjuda()
  }

  return (
    <>
      {error && <ErrorMessage title={error.message} />}

      {order && (
        <Row>
          <Grid desktop={7} tablet={12} mobile={12}>
            <div className={styles.header}>
              <Heading element='h1'>Status do pedido</Heading>
              <Divider light />
            </div>

            <div className={styles['sub-header']}>
              <Heading element='h4' noParagraph secondary>
                Detalhes do pedido
              </Heading>
              <div className={styles['order-number']}>
                <Text size='t3' dimmed>
                  Número do pedido #{subscriptionId}
                </Text>
              </div>
            </div>

            <BreadcrumbOrder orderStatus={ORDER_STATUS} currentStatus={order.status} />

            <Banner title='Importante' variant='informative'>
              Quando o acesso é liberado para o aluno, enviamos uma confirmação para o e-mail do
              responsável que foi informado durante a compra.
            </Banner>
          </Grid>

          <Grid desktop={1} tablet={12} mobile={12} className={styles['second-grid']} />

          <Grid desktop={4} tablet={12} mobile={12}>
            {order?.beginsAt && (
              <SubscriptionCard
                signatureValidity={`${getFormattedDate(
                  new Date(Number(order?.beginsAt)),
                  OPTIONS_DATE_MASK,
                )} a ${getFormattedDate(new Date(Number(order?.endAt)), OPTIONS_DATE_MASK)}`}
                orderStatus={order && order.status}
              />
            )}

            <div className={styles['promotional-card']}>
              <PromotionalCard />
            </div>
          </Grid>

          <Grid desktop={7} tablet={12} mobile={12} className={styles.footer}>
            <div className={styles['second-divider']}>
              <Divider light />
            </div>

            <div className={styles['help-center']}>
              <Text size='t3' dimmed alignment='center'>
                Para mais informações, consulte a &nbsp;
                <Text
                  element='a'
                  size='t3'
                  noUnderline
                  secondary
                  onClick={() => handleHelpCenter()}
                >
                  Central de Ajuda
                </Text>
              </Text>
            </div>
          </Grid>
        </Row>
      )}
    </>
  )
}

export default OrderStatus

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { Card, Heading, Text, Button, Divider, Icon, Badge, theme } from '@plurall/elo-beta'

import { replaceAndDecimalNumber } from 'utils/common'

import styles from './PlansCard.module.scss'

const PlansCard = ({
  freePlan,
  handleSignPlan,
  planName,
  planAmount,
  accessDays,
  index,
  cardSelected,
  setCardSelected,
  dataTestId,
  tag,
  moreEconomical,
  coverages,
}) => {
  const [cardHasSelected, setCardHasSelected] = useState(false)
  const shadow = theme.elo.shadows.default.large

  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const isTablet = useMediaQuery({
    query: theme.elo.breakpoints.tablet,
  })

  const months = accessDays / 30
  const valuePerMonth = planAmount / months.toFixed(0)

  useEffect(() => {
    if (cardSelected === index) {
      setCardHasSelected(true)
    } else {
      setCardHasSelected(false)
    }
  }, [index, cardSelected])

  return (
    <div className={styles.container}>
      <Card>
        <div
          className={moreEconomical ? styles['card-container-selected'] : styles['card-container']}
          onMouseEnter={() => setCardSelected(index)}
          style={{ boxShadow: cardHasSelected ? shadow : '' }}
        >
          <div className={styles['section-heading']}>
            <Heading
              element={isMobile || isTablet ? 'h3' : 'h2'}
              secondary={!moreEconomical}
              noParagraph
            >
              {planName}
            </Heading>
          </div>
          <div className={styles['section-divider']}>
            {moreEconomical && <Badge className={styles['heading-badge']}>Mais econômico</Badge>}
            {tag && <Badge className={styles['heading-badge']}>{tag}</Badge>}
            <Divider light />
          </div>
          <div className={styles['section-content']}>
            <section className={styles['section-plan']}>
              <div className={styles['plan-value']}>
                <div className={styles['plan-currency']}>
                  <Heading
                    element='h2'
                    dimmed={!moreEconomical}
                    noParagraph
                    variant={moreEconomical ? 'brand' : ''}
                  >
                    R$
                  </Heading>
                </div>

                <div className={styles['plan-amount']}>
                  <Heading
                    highlight
                    dimmed={!moreEconomical}
                    noParagraph
                    variant={moreEconomical ? 'brand' : ''}
                    element='h1'
                  >
                    {planAmount === 0 ? planAmount : replaceAndDecimalNumber(valuePerMonth)}
                  </Heading>
                  <Heading
                    element='h2'
                    dimmed={!moreEconomical}
                    noParagraph
                    variant={moreEconomical ? 'brand' : ''}
                  >
                    {planAmount !== 0 && '/mês'}
                  </Heading>
                </div>
              </div>
              <div className={styles['number-of-months']}>
                <Text size={isMobile || isTablet ? 't4' : 't3'} dimmed={!moreEconomical}>
                  {planAmount === 0
                    ? 'Sem mensalidade'
                    : `R$ ${replaceAndDecimalNumber(planAmount)} em ${months.toFixed(
                        0,
                      )}x parcelas de R$ ${replaceAndDecimalNumber(valuePerMonth)}/mês`}
                </Text>
              </div>
            </section>
            <section className={styles['section-plan-details']}>
              <div className={styles.details}>
                {coverages.map(coverage => (
                  <div key={coverage.title} className={styles['detail-item']}>
                    <div className={styles['details-icon']}>
                      {coverage.haveCoverage ? <Icon type='Check-big' /> : <Icon type='Cancel' />}
                    </div>
                    <Text size='t3' dimmed={isMobile || !moreEconomical}>
                      {coverage.title}
                    </Text>
                  </div>
                ))}
              </div>
            </section>
            <section className={styles['section-to-sign']}>
              <Button
                secondary={!moreEconomical}
                fullWidth
                onClick={handleSignPlan}
                dataTestId={dataTestId}
                disabled={!freePlan}
              >
                {freePlan ? 'Acessar' : 'Indisponível'}
              </Button>
            </section>
          </div>
        </div>
      </Card>
    </div>
  )
}

PlansCard.propTypes = {
  freePlan: PropTypes.bool.isRequired,
  planName: PropTypes.string.isRequired,
  planAmount: PropTypes.number.isRequired,
  handleSignPlan: PropTypes.func.isRequired,
  accessDays: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  cardSelected: PropTypes.number.isRequired,
  setCardSelected: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  moreEconomical: PropTypes.bool.isRequired,
  coverages: PropTypes.array.isRequired,
}

export default PlansCard

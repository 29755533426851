const benefits = [
  {
    key: 1,
    title: 'Sala de aula invertida',
    icon: 'Video',
    description:
      'Plano de ensino perfeito e permite que você defina tarefas de casa para alunos individuais, bem como para toda a turma.',
  },
  {
    key: 2,
    title: 'Metodologias ativas',
    icon: 'Local-download-done',
    description:
      'Utilize recursos de inteligência artificial aliados a estudos avançados de neurociência e a metodologia científica.',
  },
  {
    key: 3,
    title: 'Autonomia nos estudos',
    icon: 'Thumbs-up',
    description:
      'Defina tarefas marcadas automaticamente, monitore o progresso dos alunos e aumente as notas de suas aulas.',
  },
  {
    key: 4,
    title: 'Ensino interativo',
    icon: 'Star-small',
    description:
      'Utilize os recursos de gamificação,  para potencializar os resultados nas disciplinas e avaliações.',
  },
  {
    key: 5,
    title: 'Compartilhar turmas',
    icon: 'Love',
    description:
      'Você pode criar várias turmas, convidar seus alunos por e-mail ou um código de turma e definir a lição de casa.',
  },
]

export { benefits }

import React from 'react'
import { Heading, GridContainer, Grid, Row } from '@plurall/elo-beta'

import styles from './CustomerFeedback.module.scss'
import FeedbackCard from '../FeedbackCard/FeedbackCard'

import { imgFiveMillion } from '../../assets/images'

const CustomerFeedback = () => (
  <GridContainer>
    <Row className={styles.container}>
      <Grid data-test-id='section-title' desktop={12} className={styles['title-container']}>
        <div className={styles.title}>
          <Heading element='h1'>
            <img src={imgFiveMillion} alt='' className={styles['img-five-million']} />
            Veja o que eles dizem sobre a gente
          </Heading>
        </div>
      </Grid>
      <Grid data-test-id='section-left' desktop={6} mobile={12} className={styles['left-item']}>
        <Row className={styles.row}>
          <FeedbackCard
            content='
                Olá, só queria agradecer a plataforma de vocês e o excelente trabalho. Estudei em outro
                lugar para as provas porém não supria minhas necessidades. Conheci vocês através de um
                vídeo de estudos, fui atrás pra ver e fiquei encantada. Super recomendo!'
            author='Paula Souza, Aluna do Ensino Fundamental'
          />
        </Row>
        <Row className={styles.row}>
          <FeedbackCard
            content='
            O Plurall Adapta, é rápido, simples e interativo. Possui conteúdos
            diferenciados, é um grande facilitador para o professor e ainda
            oferece relatórios das atividades individualizados.'
            author='Angela Santos, Professor de Geográfia'
          />
        </Row>
      </Grid>
      <Grid data-test-id='section-right' desktop={6} mobile={12} className={styles['right-item']}>
        <Row className={styles.row}>
          <FeedbackCard
            content='
            O Adapta, super trás essa maneira de memorização prazerosa.
            Ajudou os alunos a memorizarem os conceitos simples de
            matemática e ainda consegue pegar aquele aluno com maior
            dificuldade de concetração.'
            author='Lúcia de Oliveira, Professor de Matemática'
          />
        </Row>
        <Row className={styles.row}>
          <FeedbackCard
            content='
              Estou muito feliz com a plataforma que me ajudou muito nas provas
              e nas questões que eu tinha dúvidas. Tudo o que eu estudei caiu na prova,
              estou muito feliz. Já indiquei para todos os meus amigos, familiares e professores'
            author='Maria Antônia, Aluna do Ensino Médio'
          />
        </Row>
      </Grid>
    </Row>
  </GridContainer>
)

export default CustomerFeedback

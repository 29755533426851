import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Heading, Text, Divider, Icon, Alert, theme } from '@plurall/elo-beta'
import { useMediaQuery } from 'react-responsive'
import { mutationCancelSubscriptions } from 'services'
import { getFormattedDate } from 'utils/formattedDate'
import { replaceAndDecimalNumber } from 'utils/common'

import styles from './MySubscriptionsCard.module.scss'

const MySubscriptionsCard = ({
  numberOrder,
  renovationDate,
  cancelationDate,
  expirationDate,
  valueDate,
  purchaseData,
  planType,
  amount,
  quarterlyPlan,
  product,
  logo,
  activeSubscription,
  id,
  canCancel,
}) => {
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const [showDetails, setShowDetails] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [textDate, setTextDate] = useState('')

  useEffect(() => {
    handleTypeDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelSubscription = async () => {
    const response = await mutationCancelSubscriptions(id)

    if (response.success) handleToast('Assinatura movida para Expiradas', 'informative')
    else handleToast(response.message, 'negative')
  }

  const handleToast = (message, variant) =>
    window?.PLURALL_ELO_TOAST_CONTAINER.addToast({
      text: message,
      variant,
      autoClose: true,
      seconds: 6,
      disableButtonClose: true,
      onDismiss: () => {},
    })

  const handleTypeDate = () => {
    let text

    if (renovationDate) text = 'Data da renovação'
    else if (cancelationDate) text = 'Data do cancelamento'
    else if (expirationDate) text = 'Data da expiração'

    setTextDate(text)
  }

  const handleShowAlert = () => (
    <Alert
      onPrimaryClick={handleCancelSubscription}
      onSecondaryClick={() => setShowAlert(false)}
      primaryButtonDataTestId='primary-button'
      primaryButtonText='Confirmar'
      secondaryButtonDataTestId='cancel-option'
      secondaryButtonText='Voltar'
      title={renovationDate ? 'Confirmar cancelamento' : 'Confirmar ativação'}
      variant='attention'
    >
      <div className={styles['alert-container']}>
        <Text size='t3' dimmed>
          Se você {renovationDate ? 'cancelar' : 'ativar'} a assinatura, o seu acesso à solução será{' '}
          {renovationDate ? 'bloqueado' : 'ativado'} em breve.
          <Text size='t3' bold dimmed>
            Você poderá assiná-la novamente.
          </Text>
        </Text>
      </div>
    </Alert>
  )

  const handleTotalAmount = value => {
    const total = value.replace('.', ',')

    if (quarterlyPlan) {
      return `R$ ${total} em 3x de ${replaceAndDecimalNumber(value / 3)}`
    }

    return `R$ ${total} em 12x de ${replaceAndDecimalNumber(value / 12)}`
  }

  return (
    <Card dataTestId='subscription-card'>
      <div className={styles['card-container']}>
        <div className={styles['header-card']}>
          <section className={styles['section-img-card']}>
            <img className={styles['img-card']} src={logo} alt='Imagem do card de assinatura' />
          </section>
          <section className={styles['section-description']}>
            <Heading element='h5' noParagraph>
              {product}
            </Heading>
            <Text size={isMobile ? 't5' : 't3'} dimmed>
              Pedido: {numberOrder}
            </Text>
            <br />
            <Text size={isMobile ? 't5' : 't3'} semibold dimmed>
              {textDate} {getFormattedDate(parseFloat(valueDate))}
            </Text>
          </section>
        </div>

        <Divider light />

        <section className={styles['section-details-card']}>
          <div className={styles['details-header']}>
            <div className={styles['details-text']}>
              <Text
                onClick={() => setShowDetails(!showDetails)}
                size='t3'
                semibold
                brand
                element='label'
              >
                {showDetails ? 'Ocultar' : 'Detalhes'}
              </Text>
              <div className={styles['icon-header']}>
                <Icon type='Chevron-right' dataTestId='icon-header-card' />
              </div>
            </div>
          </div>
          <div className={styles['details-content']}>
            <div className={showDetails ? styles['show-details'] : styles['hide-details']}>
              <section className={styles['section-current-plan']}>
                <div className={styles['current-plan-content']}>
                  <Heading element='h5' noParagraph>
                    Plano atual
                  </Heading>

                  <div className={styles['items-container']}>
                    <div className={styles['item-current-plan']}>
                      <Text size='t5' semibold shy>
                        TIPO
                      </Text>

                      <span className={styles['item-current-plan']}>
                        <Text size={isMobile ? 't5' : 't3'} secondary>
                          {planType}
                        </Text>
                      </span>
                    </div>

                    <div className={styles['item-current-plan']}>
                      <Text size='t5' semibold shy>
                        DATA DA COMPRA
                      </Text>

                      <span className={styles['item-current-plan']}>
                        <Text size={isMobile ? 't5' : 't3'} secondary>
                          {getFormattedDate(parseFloat(purchaseData))}
                        </Text>
                      </span>
                    </div>

                    <div className={styles['item-current-plan']}>
                      <Text size='t5' semibold shy>
                        DATA DA RENOVAÇÃO
                      </Text>

                      <span className={styles['item-current-plan']}>
                        <Text size={isMobile ? 't5' : 't3'} secondary>
                          {getFormattedDate(parseFloat(valueDate))}
                        </Text>
                      </span>
                    </div>
                  </div>

                  <div className={styles.divider}>
                    <Divider light />
                  </div>
                </div>
              </section>

              <section className={styles['section-payment-details']}>
                <div className={styles['current-plan-content']}>
                  <Heading element='h5' noParagraph>
                    Valor do pagamento
                  </Heading>

                  <div className={styles['items-container']}>
                    <span className={styles['item-current-plan']}>
                      <Text size='t5' semibold shy>
                        VALOR TOTAL
                      </Text>
                    </span>
                  </div>

                  <div className={styles['items-container']}>
                    <span className={styles['last-item-current-plan']}>
                      <Text size={isMobile ? 't5' : 't3'} secondary>
                        {handleTotalAmount(amount.toFixed(2))}
                      </Text>
                    </span>
                  </div>
                </div>
                {activeSubscription && (
                  <>
                    <div className={styles['section-divider']}>
                      <Divider light />
                    </div>
                    <div className={styles['section-cancel-subscription']}>
                      <Text
                        size='t2'
                        secondary
                        bold
                        onClick={() => canCancel && setShowAlert(true)}
                        shy={!canCancel}
                      >
                        <span className={styles['cancel-subscription-text']}>
                          Cancelar assinatura
                        </span>
                      </Text>
                      <Text size='t4' secondary>
                        <span className={styles.subtitle}>
                          A assinatura pode ser cancelada a qualquer momento, seguindo a nossa
                          &nbsp;
                          <Text
                            size='t4'
                            element='a'
                            href='https://www.plurall.net/store-politica-de-cancelamento'
                            target='_blank'
                            noUnderline
                          >
                            Política de cancelamento.
                          </Text>
                        </span>
                      </Text>

                      {!canCancel && (
                        <div className={styles['cancel-order-text']}>
                          <Text size='t3' variant='negative'>
                            O pedido de cancelamento não pode ultrapassar 7 dias a partir do início
                            da assinatura.
                          </Text>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </section>
            </div>
          </div>
        </section>
      </div>

      {showAlert && handleShowAlert()}
    </Card>
  )
}

MySubscriptionsCard.propTypes = {
  numberOrder: PropTypes.string.isRequired,
  renovationDate: PropTypes.bool,
  cancelationDate: PropTypes.bool,
  expirationDate: PropTypes.bool,
  valueDate: PropTypes.string.isRequired,
  purchaseData: PropTypes.string.isRequired,
  planType: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  quarterlyPlan: PropTypes.bool.isRequired,
  product: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  activeSubscription: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  canCancel: PropTypes.bool.isRequired,
}

export default MySubscriptionsCard

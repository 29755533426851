import React from 'react'
import PropTypes from 'prop-types'
import { BANNER_IMG_NAMES } from 'constants/mocks'

const Slide = ({ dataTestId, image }) => {
  const handleViewport = () => {
    const width = window.innerWidth
    if (width < 767) {
      return 'mobile'
    }
    if (width < 1200) {
      return 'tablet'
    }
    return 'desktop'
  }
  const handleAlt = () =>
    BANNER_IMG_NAMES[image].alt || 'Banner principal da landing page do Plurall Adapta'

  const handleLink = () => BANNER_IMG_NAMES[image].url

  const handleImg = () => {
    const cdn = process.env.REACT_APP_ASSETS_URL
    const viewport = handleViewport()
    const img = BANNER_IMG_NAMES[image][viewport]
    return `${cdn}/static/assets/images/plurall-store/${img}.jpg`
  }

  const img = handleImg()
  const alt = handleAlt()
  const link = handleLink()

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <a href={link} target='_blank' rel='noopener noreferrer'>
      <img src={img} dataTestId={dataTestId} alt={alt} />
    </a>
  )
}

Slide.propTypes = {
  image: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
}

export default Slide

import 'core-js/stable'
import 'react-app-polyfill/ie9'

import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { App, Error, LoginCallback } from 'views'
import { createBrowserHistory } from 'history'
import { PrivateRoute } from 'components'
import { Route, Router, Switch } from 'react-router-dom'

let environment = process.env.REACT_APP_NODE_ENV
if (environment === 'pull_request' || environment === 'local') {
  environment = 'staging'
}

window.PLURALL_TRACKER = new window.PlurallTracker({
  clientId: 'store',
  environment,
  plurallFeedback: 1001,
  hotjarId: 2419835,
  isB2C: true,
})

const customHistory = createBrowserHistory()
window.PLURALL_CUSTOM_HISTORY = customHistory
customHistory.listen(() => window.PLURALL_TRACKER.pageView())

const { REACT_APP_NODE_ENV, REACT_APP_SENTRY_CLIENT } = process.env
if (REACT_APP_SENTRY_CLIENT) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_CLIENT,
    environment: REACT_APP_NODE_ENV,
  })
}

ReactDOM.render(
  <Router history={customHistory}>
    <Switch>
      <Route path='/login/callback' component={LoginCallback} />
      <Route path='/error' render={props => <Error {...props} />} />
      <PrivateRoute path='/' component={App} />
    </Switch>
  </Router>,
  document.getElementById('root'),
)

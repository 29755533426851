import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Heading, Text, theme } from '@plurall/elo-beta'
import { useMediaQuery } from 'react-responsive'
import { UserContext } from 'contexts'

import styles from './NoSubscriptions.module.scss'

const NoSubscriptions = ({ title }) => {
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const { user } = useContext(UserContext)

  const linkCatalog = user.roles.some(u => u.name === 'Aluno')
    ? `${process.env.REACT_APP_HOST}/family`
    : process.env.REACT_APP_HOST

  return (
    <div className={styles.container}>
      <Heading element={isMobile ? 'h3' : 'h2'} noParagraph textAlign='center'>
        {title}
      </Heading>

      <div className={styles.subtitle}>
        <Text size={isMobile ? 't4' : 't2'} alignment='center'>
          Quer aprender de forma divertida e eficiente, aliando tecnologia e educação? Conheça e
          assine as soluções educacionais Plurall Store.
        </Text>
      </div>

      <Text
        size={isMobile ? 't4' : 't3'}
        element='a'
        href={linkCatalog}
        alignment='center'
        noUnderline
      >
        Conferir catálogo Plurall Store
      </Text>
    </div>
  )
}

NoSubscriptions.propTypes = {
  title: PropTypes.string.isRequired,
}

export default NoSubscriptions

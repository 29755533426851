import React from 'react'
import { Text, ControlGroup, ControlGroupItem } from '@plurall/elo-beta'

import styles from './QuickAccess.module.scss'

const QuickAccess = () => (
  <div className={styles.container}>
    <div className={styles.title}>
      <Text size='t5' bold>
        ACESSO RÁPIDO
      </Text>
    </div>

    <ControlGroup noCard>
      <ControlGroupItem
        navigationItem
        onClick={() => window.PLURALL_ELO.openCentralAjuda()}
        dataTestId='help-center'
      >
        Central de ajuda
      </ControlGroupItem>
      <ControlGroupItem
        navigationItem
        onClick={() => window.open(process.env.REACT_APP_PRIVACY_POLICY, '_blank')}
        dataTestId='privacy-policy'
      >
        Política de privacidade
      </ControlGroupItem>
      <ControlGroupItem
        navigationItem
        onClick={() => window.open(process.env.REACT_APP_USE_TERMS, '_blank')}
        dataTestId='use-terms'
      >
        Termos de uso
      </ControlGroupItem>
    </ControlGroup>
  </div>
)

export default QuickAccess

import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'
import { ErrorMessage, Layout } from 'components'

class Error extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  state = {}

  componentDidMount = () => {
    const { status, message } = queryString.parse(this.props.location.search)

    this.setState({
      msg:
        message !== 'undefined'
          ? message
          : 'Ops! Ocorreu um erro. Caso o erro persista, entre em contato com o suporte.',
    })

    const { REACT_APP_ASSETS_URL } = process.env

    switch (Number(status)) {
      case 403:
        this.setState({
          image: null,
          msg: 'Você não possui acesso a esta página',
          subTitle:
            'O Plurall Adapta está disponível somente para alunos e professores de escolas que aderiram a este serviço.',
        })
        break
      case 404:
        this.setState({
          image: `${REACT_APP_ASSETS_URL}/static/assets/images/tired.svg`,
        })
        break
      case 500:
        this.setState({
          image: `${REACT_APP_ASSETS_URL}/static/assets/images/what.svg`,
        })
        break
      default:
        this.setState({
          image: `${REACT_APP_ASSETS_URL}/static/assets/images/what.svg`,
        })
        break
    }
  }

  render() {
    return (
      <Layout>
        <ErrorMessage
          title={this.state.msg}
          src={this.state.image}
          subTitle={this.state.subTitle}
        />
      </Layout>
    )
  }
}

export default Error

import React from 'react'
import PropTypes from 'prop-types'
import { Text, Icon, Divider } from '@plurall/elo-beta'
import { STATUS_SERVICE } from 'constants/mocks'

import styles from './BreadcrumbOrder.module.scss'

const BreadcrumbOrder = ({ orderStatus, currentStatus }) => {
  const lastIndex = orderStatus.length - 1
  const isServiceActive = currentStatus === STATUS_SERVICE.active
  return (
    <div className={styles.container}>
      <ul className={styles['breadcrumb-container']}>
        {orderStatus.map((item, index) => (
          <div key={item.id} className={styles.content}>
            <div className={styles['left-content']}>
              <Icon
                type='Check-circle'
                size='big'
                secondary
                shy={!(isServiceActive || item.success)}
                variant={(isServiceActive || item.success) && 'positive'}
                dataTestId={`check-circle-icon-${index}`}
              />
              {index !== lastIndex && (
                <div className={styles['divider-container']}>
                  <Divider vertical light data-test-id='divider' />
                </div>
              )}
            </div>

            <div className={styles['right-content']}>
              <Text size='t3'>{item.name}</Text>
              {/* TODO We need to remove comment when the date data to be available */}
              {/* <Text size='t4' dimmed>
                {item.date}
              </Text> */}
            </div>
          </div>
        ))}
      </ul>
    </div>
  )
}

BreadcrumbOrder.propTypes = {
  orderStatus: PropTypes.array.isRequired,
  currentStatus: PropTypes.string.isRequired,
}

export default BreadcrumbOrder

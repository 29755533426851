import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Dialog, Input } from '@plurall/elo-beta'
import { handleRedirectSeneca, validateEmail } from 'utils/common'
import { SUBSCRIPTION_PLAN_ID } from 'constants/mocks'
import { trackingEventNames } from 'constants/tracking'

import { mutationCreateOrder, postResponsible } from 'services'

import { useResponsibleDialog } from 'contexts/ResponsibleDialogContext'

const ResponsibleDialog = ({ user }) => {
  const { closeResponsibleDialog } = useResponsibleDialog()

  const [errors, setErrors] = useState({ email: true, name: true })
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const createOrder = async () => {
    await mutationCreateOrder(SUBSCRIPTION_PLAN_ID)
    window.scrollTo(0, 0)
    window.location.reload()
  }

  const handleAccessPlurall = async () => {
    window.PLURALL_TRACKER.track(trackingEventNames.addReponsibleDataClick)

    const userData = {
      userid: user.id,
      name,
      email,
    }

    await postResponsible(userData)

    handleRedirectSeneca()
    createOrder()
    closeResponsibleDialog()
  }

  const handleValidateName = () => {
    const validName = name.length > 2

    validName
      ? setErrors(prev => ({ ...prev, name: false }))
      : setErrors(prev => ({ ...prev, name: true }))
  }

  const handleValidateEmail = () => {
    const validEmail = validateEmail(email)

    validEmail
      ? setErrors(prev => ({ ...prev, email: false }))
      : setErrors(prev => ({ ...prev, email: true }))
  }

  const handleCloseDialog = () => {
    window.PLURALL_TRACKER.track(trackingEventNames.cancelRegisterResponsibleClick)
    closeResponsibleDialog()
  }

  const handleClickMaybeLater = () => {
    closeResponsibleDialog()

    window.PLURALL_TRACKER.track(trackingEventNames.goToSeneca)
    window.open(process.env.REACT_APP_LINK_SENECA)
    createOrder()
  }

  return (
    <Dialog
      title='Potencialize seu aprendizado e desempenho escolar'
      description='Informe o e-mail do seu pai, mãe ou responsável para aprovação da sua conta no Plurall Adapta.'
      primaryButtonText='Acessar Plurall Adapta'
      secondaryButtonText='Talvez depois'
      onClose={handleCloseDialog}
      onPrimaryClick={handleAccessPlurall}
      onSecondaryClick={handleClickMaybeLater}
      primaryButtonDisabled={errors.email || errors.name}
    >
      <div>
        <Input
          type='text'
          value={name}
          onChange={value => setName(value)}
          onBlur={() => handleValidateName()}
          placeholder='Nome do responsável*'
          cancelTabIndex='-1'
        />
        <Input
          type='email'
          value={email}
          onChange={value => setEmail(value)}
          onBlur={() => handleValidateEmail()}
          placeholder='E-mail do responsável*'
        />
      </div>
    </Dialog>
  )
}

ResponsibleDialog.propTypes = {
  user: PropTypes.object,
}

export default ResponsibleDialog

import React, { useEffect } from 'react'
import { Switch, useHistory } from 'react-router-dom'

import { Home, OrderStatus, MySubscriptions, Family, MyPayments } from 'views'
import { Layout, PrivateRoute } from 'components'
import { ResponsibleDialogProvider, UserProvider } from 'contexts'
import { Spinner } from '@plurall/elo-beta'
import { useActiveUser } from 'hooks'

import './App.module.scss'

const App = () => {
  const history = useHistory()
  useEffect(() => {
    window.PLURALL_TRACKER.pageView()
  }, [])

  const { isActiveUser, isFetching } = useActiveUser()

  if (isActiveUser === false) {
    window.location.href = `/error?status=${403}`
    return null
  }

  const renderContent = () => (
    <UserProvider>
      <Switch>
        <PrivateRoute path='/adapta/meus-pagamentos' component={MyPayments} />
        <PrivateRoute path='/adapta/family' component={Family} />
        <PrivateRoute path='/adapta/minhas-assinaturas' component={MySubscriptions} />
        <PrivateRoute path='/adapta/status-pedido/:subscriptionId' component={OrderStatus} />

        <ResponsibleDialogProvider>
          <PrivateRoute path='/adapta' component={Home} />
        </ResponsibleDialogProvider>
      </Switch>
    </UserProvider>
  )

  return (
    <Layout location={history.location}>
      {isActiveUser && !isFetching ? renderContent() : <Spinner size='large' />}
    </Layout>
  )
}

export default App

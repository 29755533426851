import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Text, Button, Heading } from '@plurall/elo-beta'

import { imgPluralzinho } from '../../../../assets/images'
import styles from './PromotionalCard.module.scss'

const PromotionalCard = () => {
  const history = useHistory()

  const handleAcessStore = () => {
    history.push('/adapta')
  }

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles['card-container']}>
          <div>
            <Heading element='h6' noParagraph>
              <span className={styles['heading-text']}>Plurall Store</span>
            </Heading>

            <Text size='t4'>
              <span className={styles['subtitle-text']}>
                Conheça as soluções educacionais que vão reforçar os seus estudos e melhorar os seus
                resultados.
              </span>
            </Text>
            <div className={styles['know-more-button']}>
              <Button
                aria-label='button-saiba-mais'
                secondary
                size='small'
                onClick={handleAcessStore}
              >
                Saiba mais
              </Button>
            </div>
          </div>

          <div className={styles.pluralzinho}>
            <img
              src={imgPluralzinho}
              alt='Imagem do pluralzinho'
              className={styles['img-pluralzinho']}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default PromotionalCard

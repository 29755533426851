import React from 'react'
import { Heading, Text, theme } from '@plurall/elo-beta'
import { useMediaQuery } from 'react-responsive'

import styles from './ForStudents.module.scss'
import Topics from '../Topics/Topics'

import {
  duasXMelhor,
  duasXMelhorMobile,
  crieTrilhas,
  crieTrilhasMobile,
  imgFiveMillion,
} from '../../assets/images'

const ForEstudents = () => {
  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const isTablet = useMediaQuery({
    query: theme.elo.breakpoints.tablet,
  })

  return (
    <div className={styles.container}>
      <div className={styles['first-section']}>
        <div className={styles['left-item']}>
          <div className={styles.brand}>
            <Text size='t5' dimmed>
              PARA ALUNOS
            </Text>
          </div>
          <div>
            <div className={styles['section-title']}>
              <Heading element='h1' noParagraph>
                Aprenda até
                <span className={styles['five-million']}>
                  <img
                    src={imgFiveMillion}
                    alt='Imagem de rabisco abaixo do texto: 5 milhões'
                    className={styles['img-five-million']}
                  />
                  2 vezes
                </span>
                mais rápido com metodologia baseada em neurociência
              </Heading>
            </div>

            <br />
            <div className={styles['section-subtitle']}>
              <Text size={isMobile || isTablet ? 't3' : 't1'} secondary={!isMobile}>
                Acesse milhares de materiais e atividades para aprender de forma dinâmica, divertida
                e eficiente.
              </Text>
            </div>
            <Topics
              texts={[
                'Textos  curtos, animações e exercícios interativos',
                'Atividades corrigidas em tempo real',
                'Resumos e revisão de conteúdo de forma simples e divertida',
              ]}
              width='85'
            />
          </div>
        </div>
        <div className={styles['right-item']}>
          <img
            src={duasXMelhorMobile}
            alt='Imagem da sessão do aprendizado 2 vezes melhor'
            className={styles['img-two-better-mobile']}
            data-test-id='img-two-better-mobile'
          />
          <img
            src={duasXMelhor}
            alt='Imagem da sessão do aprendizado 2 vezes melhor'
            className={styles['img-two-better']}
            data-test-id='img-two-better-desktop'
          />
        </div>
      </div>
      <div className={styles['second-section']}>
        <div className={styles['left-item']}>
          <img
            src={crieTrilhasMobile}
            alt='Imagem da criação de trilhas individuais de aprendizagem'
            className={styles['img-trails-mobile']}
            data-test-id='img-trails-mobile'
          />
          <img
            src={crieTrilhas}
            alt='Imagem da criação de trilhas individuais de aprendizagem'
            className={styles['img-trails']}
            data-test-id='img-trails-desktop'
          />
        </div>
        <div className={styles['right-item']}>
          <div className={styles.title}>
            <Heading element={isTablet ? 'h3' : 'h2'} noParagraph>
              Potencialize seu aprendizado e desempenho escolar
            </Heading>
          </div>

          <div className={styles['section-subtitle']}>
            <Text size={isMobile || isTablet ? 't3' : 't1'} secondary={!isMobile}>
              A plataforma identifica seus pontos fortes e fracos por meio de um algoritmo gênio
              baseado em neurociência e cria trilhas personalizadas para a sua necessidade, para
              você aprender cada vez mais.
            </Text>
          </div>

          <div className={styles['section-topics']}>
            <Topics texts={['Inteligência artificial adaptativa', 'Desempenho otimizado']} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForEstudents

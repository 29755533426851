import React from 'react'
import { Text, Icon } from '@plurall/elo-beta'
import PropTypes from 'prop-types'

import styles from './Topics.module.scss'

const Topics = ({ texts }) => (
  <div className={styles.container}>
    {texts.map((title, index) => (
      <div key={title} className={styles['topic-item']}>
        <Icon type='Star-empty' />
        <Text size='t2' bold>
          <span className={styles['topic-text']} data-test-id={`title-${index}`}>
            {title}
          </span>
        </Text>
      </div>
    ))}
  </div>
)

Topics.propTypes = {
  texts: PropTypes.array.isRequired,
}

export default Topics

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { trackingEventNames } from 'constants/tracking'
import { Card, Text, Divider, Icon, Button, Tooltip } from '@plurall/elo-beta'
import { STATUS_SERVICE } from 'constants/mocks'

import styles from './SubscriptionCard.module.scss'

const SubscriptionCard = ({ signatureValidity, orderStatus }) => {
  const history = useHistory()

  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleAcessAdapta = () => {
    window.PLURALL_TRACKER.track(trackingEventNames.accessAdaptaClick)
    window.PLURALL_TRACKER.track(trackingEventNames.goToSeneca)
    window.location.href = process.env.REACT_APP_LINK_SENECA
  }

  const handleManageSubscription = () => {
    window.PLURALL_TRACKER.track(trackingEventNames.manageSubscriptionsClick)

    history.push('/adapta/subscription')
  }

  const handleToolTip = () => {
    !activeStatus ? setTooltipVisible(true) : setTooltipVisible(false)
  }

  const activeStatus = orderStatus === STATUS_SERVICE.active

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles['card-container']}>
          <div className={styles.header}>
            <div className={styles['header-title']}>
              <Text size='t2' semibold>
                Plurall Adapta by Seneca
              </Text>
              <div className={styles['header-icon']}>
                <Icon type='badge-check' secondary variant='informative' />
              </div>
            </div>

            <Divider light />
          </div>
          <div className={styles['signature-validity']}>
            <div className={styles['signature-content']}>
              <div className={styles['signature-icon']}>
                <Icon type='Calendar' shy />
              </div>
              <div className={styles['signature-details']}>
                <Text size='t3' semibold>
                  Validade da assinatura &nbsp;
                </Text>
                <Text size='t3' dimmed>
                  {signatureValidity}
                </Text>
              </div>
            </div>

            <Divider light />
          </div>
          <div className={styles['card-actions']}>
            <Tooltip delay={2000}>
              <div className={tooltipVisible ? styles.tooltip : styles['tooltip-hidden']}>
                O seu acesso será liberado em breve. Por favor, aguarde mais um pouco.
              </div>
            </Tooltip>
            <div className={styles['access-adapta-button']}>
              <Button
                fullWidth
                onClick={() => handleAcessAdapta()}
                disabled={!activeStatus}
                inverted={!activeStatus}
                onMouseOver={() => handleToolTip()}
                onFocus={() => handleToolTip()}
                onMouseOut={() => setTooltipVisible(false)}
                onBlur={() => setTooltipVisible(false)}
              >
                Acessar Plurall Adapta
              </Button>
            </div>

            <Button fullWidth onClick={() => handleManageSubscription()} secondary>
              Gerenciar assinaturas
            </Button>
          </div>
          <div className={styles['footer-card']}>
            <Text size='t4'>
              A assinatura pode ser cancelada a qualquer momento, seguindo a nossa &nbsp;
              <Text
                size='t4'
                element='a'
                href='https://www.plurall.net/store-politica-de-cancelamento'
                noUnderline
              >
                Política de cancelamento.
              </Text>
            </Text>
          </div>
        </div>
      </Card>
    </div>
  )
}

SubscriptionCard.propTypes = {
  signatureValidity: PropTypes.string.isRequired,
  orderStatus: PropTypes.string.isRequired,
}

export default SubscriptionCard
